import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { TextField, Button, Typography } from '@material-ui/core';
import { getGood, getGoods } from "../api/fetch";
import ReactGA from "react-ga4";
import { Constants } from '../config/constants';
import AdSense from '../components/googleAds';
import { goods } from '../goods'

const theme = createTheme();

const GoodsItem = () => {
    const { goodsName } = useParams();
    const [data, setData] = useState([]);
    const [item, setItem] = useState([]);

  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: `/goodsItem/${goodsName}`, title: "Goods Item Page Visit" });
 
    let data = null;
    if(Constants.takeDataFromFirebase){
      data = localStorage.getItem(Constants.cached_goods);
      data = JSON.parse(data);
    } else {
      data = goods
    }
    if (data) {
      setData(data);
    } else {
      getGoodsData();
    }
}, []);

const getGoodsData = () => {
    const data = getGoods();
    console.log("Firebase API called on goods item page for goods");
    Promise.resolve(data).then((res) => {
      setData(res);
      localStorage.setItem(Constants.cached_goods, JSON.stringify(res));
    });
  };

useEffect (() => {
    const x= data.find(item => item.name=== goodsName);
    console.log(x);
    setItem(x);
}, [goodsName]) 

  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AdSense />
        <Container component="main" maxWidth="xs" sx={{ 
			backgroundColor: 'rgba(255, 255, 255, 0.8)',
			backdropFilter: 'blur(5px)',
			borderRadius: '10px',
			padding: '30px',
			margin: '5% auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
            height: '90vh'
		  }}>
          <Typography variant="h5" component="h1" gutterBottom>
            Goods
          </Typography>
          <h1>{goodsName}</h1>
          
          
  </Container>

</ThemeProvider>
);
};
export default GoodsItem;