export const goods = [
  {
    name: "axe",
    level: 1,
    price: 10,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/f/fb/Axe.png",
  },
  {
    name: "dynamite",
    level: 1,
    price: 7,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/f/fc/Dynamite.png",
  },
  {
    name: "saw",
    level: 1,
    price: 15,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/0/0a/Saw.png",
  },
  {
    name: "shovel",
    level: 1,
    price: 30,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/7/77/Shovel.png",
  },
  {
    name: "TNT barrel",
    level: 1,
    price: 20,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/69/TNT_Barrel.png",
  },
  {
    name: "Pickaxe",
    level: 34,
    price: 126,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/b/b7/Pickaxe.png",
  },
  {
    name: "bolt",
    level: 1,
    price: 270,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/8/8e/Bolt.png",
  },
  {
    name: "plank",
    level: 1,
    price: 270,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/0/0d/Plank.png",
  },
  {
    name: "duct tape",
    level: 1,
    price: 270,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/4/4e/Duct_Tape.png",
  },
  {
    name: "nail",
    level: 1,
    price: 270,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/4/40/Nail.png",
  },
  {
    name: "screw",
    level: 1,
    price: 270,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/5/59/Screw.png",
  },
  {
    name: "wood panel",
    level: 1,
    price: 270,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/02/Wood_Panel.png",
  },
  {
    name: "land deed",
    level: 22,
    price: 403,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e0/Land_Deed.png",
  },
  {
    name: "marker stake",
    level: 22,
    price: 403,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/91/Marker_Stake.png",
  },
  {
    name: "mallet",
    level: 22,
    price: 403,
    time: 0,
    xp: 0,
    needs: [],
    source: "tools",
    image: "https://static.wikia.nocookie.net/hayday/images/7/7b/Mallet.png",
  },
  {
    name: "wheat",
    level: 1,
    price: 3,
    time: 120,
    xp: 1,
    needs: [
      {
        name: "wheat",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e2/Wheat.png",
  },
  {
    name: "egg",
    level: 1,
    price: 18,
    time: 1200,
    xp: 2,
    needs: [
      {
        name: "chicken feed",
        quantity: 1,
      },
    ],
    source: "chicken",
    image: "https://static.wikia.nocookie.net/hayday/images/2/26/Egg.png",
  },
  {
    name: "corn",
    level: 2,
    price: 7,
    time: 300,
    xp: 1,
    needs: [
      {
        name: "corn",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/f/f8/Corn.png",
  },
  {
    name: "bread",
    level: 2,
    price: 21,
    time: 240,
    xp: 3,
    needs: [
      {
        name: "wheat",
        quantity: 3,
      },
    ],
    source: "bakery",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e1/Bread.png",
  },
  {
    name: "chicken feed",
    level: 3,
    price: 7,
    time: 240,
    xp: 1,
    needs: [
      {
        name: "corn",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "feed mill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/6a/Chicken_Feed.png",
  },
  {
    name: "soyabean",
    level: 5,
    price: 10,
    time: 1200,
    xp: 2,
    needs: [
      {
        name: "soyabean",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/9/9e/Soybean.png",
  },
  {
    name: "cow feed",
    level: 6,
    price: 14,
    time: 480,
    xp: 2,
    needs: [
      {
        name: "corn",
        quantity: 1,
      },
      {
        name: "soyabean",
        quantity: 2,
      },
    ],
    source: "feed mill",
    image: "https://static.wikia.nocookie.net/hayday/images/f/f6/Cow_Feed.png",
  },
  {
    name: "milk",
    level: 6,
    price: 32,
    time: 3600,
    xp: 3,
    needs: [
      {
        name: "cow feed",
        quantity: 1,
      },
    ],
    source: "cow",
    image: "https://static.wikia.nocookie.net/hayday/images/9/92/Milk.png",
  },
  {
    name: "cream",
    level: 6,
    price: 50,
    time: 1020,
    xp: 6,
    needs: [
      {
        name: "milk",
        quantity: 1,
      },
    ],
    source: "dairy",
    image: "https://static.wikia.nocookie.net/hayday/images/8/83/Cream.png",
  },
  {
    name: "sugarcane",
    level: 7,
    price: 14,
    time: 1800,
    xp: 3,
    needs: [
      {
        name: "sugarcane",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/0/03/Sugarcane.png",
  },
  {
    name: "corn bread",
    level: 7,
    price: 72,
    time: 1500,
    xp: 8,
    needs: [
      {
        name: "corn",
        quantity: 2,
      },
      {
        name: "egg",
        quantity: 2,
      },
    ],
    source: "bakery",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/1d/Corn_Bread.png",
  },
  {
    name: "brown sugar",
    level: 7,
    price: 32,
    time: 1020,
    xp: 4,
    needs: [
      {
        name: "sugarcane",
        quantity: 1,
      },
    ],
    source: "sugar mill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/02/Brown_Sugar.png",
  },
  {
    name: "popcorn",
    level: 8,
    price: 32,
    time: 1500,
    xp: 4,
    needs: [
      {
        name: "corn",
        quantity: 2,
      },
    ],
    source: "popcorn pot",
    image: "https://static.wikia.nocookie.net/hayday/images/8/85/Popcorn.png",
  },
  {
    name: "carrot",
    level: 9,
    price: 7,
    time: 600,
    xp: 2,
    needs: [
      {
        name: "carrot",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/c/c3/Carrot.png",
  },
  {
    name: "butter",
    level: 9,
    price: 82,
    time: 1500,
    xp: 10,
    needs: [
      {
        name: "milk",
        quantity: 2,
      },
    ],
    source: "dairy",
    image: "https://static.wikia.nocookie.net/hayday/images/f/f1/Butter.png",
  },
  {
    name: "pancake",
    level: 9,
    price: 108,
    time: 1500,
    xp: 13,
    needs: [
      {
        name: "brown sugar",
        quantity: 1,
      },
      {
        name: "egg",
        quantity: 3,
      },
    ],
    source: "BBQ grill",
    image: "https://static.wikia.nocookie.net/hayday/images/6/62/Pancake.png",
  },
  {
    name: "pig feed",
    level: 10,
    price: 14,
    time: 1020,
    xp: 2,
    needs: [
      {
        name: "carrot",
        quantity: 2,
      },
      {
        name: "soyabean",
        quantity: 1,
      },
    ],
    source: "feed mill",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e4/Pig_Feed.png",
  },
  {
    name: "bacon",
    level: 10,
    price: 50,
    time: 14400,
    xp: 5,
    needs: [
      {
        name: "pig feed",
        quantity: 1,
      },
    ],
    source: "pig",
    image: "https://static.wikia.nocookie.net/hayday/images/f/f6/Bacon.png",
  },
  {
    name: "cookie",
    level: 10,
    price: 104,
    time: 3060,
    xp: 13,
    needs: [
      {
        name: "brown sugar",
        quantity: 1,
      },
      {
        name: "egg",
        quantity: 2,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "bakery",
    image: "https://static.wikia.nocookie.net/hayday/images/7/70/Cookie.png",
  },
  {
    name: "bacon and eggs",
    level: 11,
    price: 201,
    time: 3060,
    xp: 24,
    needs: [
      {
        name: "bacon",
        quantity: 2,
      },
      {
        name: "egg",
        quantity: 4,
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a6/Bacon_and_Eggs.png",
  },
  {
    name: "cheese",
    level: 12,
    price: 122,
    time: 3060,
    xp: 15,
    needs: [
      {
        name: "milk",
        quantity: 3,
      },
    ],
    source: "dairy",
    image: "https://static.wikia.nocookie.net/hayday/images/a/a5/Cheese.png",
  },
  {
    name: "indigo",
    level: 13,
    price: 25,
    time: 7200,
    xp: 5,
    needs: [
      {
        name: "indigo",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/4/48/Indigo.png",
  },
  {
    name: "white sugar",
    level: 13,
    price: 50,
    time: 2040,
    xp: 6,
    needs: [
      {
        name: "sugarcane",
        quantity: 2,
      },
    ],
    source: "sugar mill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/ff/White_Sugar.png",
  },
  {
    name: "carrot pie",
    level: 14,
    price: 82,
    time: 3060,
    xp: 10,
    needs: [
      {
        name: "carrot",
        quantity: 3,
      },
      {
        name: "egg",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "pie oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/0b/Carrot_Pie.png",
  },
  {
    name: "pumpkin",
    level: 15,
    price: 32,
    time: 10800,
    xp: 6,
    needs: [
      {
        name: "pumpkin",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/6/64/Pumpkin.png",
  },
  {
    name: "pumpkin pie",
    level: 15,
    price: 158,
    time: 6120,
    xp: 19,
    needs: [
      {
        name: "egg",
        quantity: 1,
      },
      {
        name: "pumpkin",
        quantity: 3,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "pie oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7d/Pumpkin_Pie.png",
  },
  {
    name: "apple",
    level: 15,
    price: 39,
    time: 57600,
    xp: 7,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/7/7d/Apple.png",
  },
  {
    name: "sheep feed",
    level: 16,
    price: 14,
    time: 1500,
    xp: 3,
    needs: [
      {
        name: "soyabean",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 3,
      },
    ],
    source: "feed mill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/58/Sheep_Feed.png",
  },
  {
    name: "wool",
    level: 16,
    price: 54,
    time: 21600,
    xp: 5,
    needs: [
      {
        name: "sheep feed",
        quantity: 1,
      },
    ],
    source: "sheep",
    image: "https://static.wikia.nocookie.net/hayday/images/3/34/Wool.png",
  },
  {
    name: "buttered popcorn",
    level: 16,
    price: 126,
    time: 3060,
    xp: 15,
    needs: [
      {
        name: "butter",
        quantity: 1,
      },
      {
        name: "corn",
        quantity: 2,
      },
    ],
    source: "popcorn pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/88/Buttered_Popcorn.png",
  },
  {
    name: "sweater",
    level: 17,
    price: 151,
    time: 6120,
    xp: 18,
    needs: [
      {
        name: "wool",
        quantity: 2,
      },
    ],
    source: "loom",
    image: "https://static.wikia.nocookie.net/hayday/images/7/73/Sweater.png",
  },
  {
    name: "cotton",
    level: 18,
    price: 28,
    time: 9000,
    xp: 6,
    needs: [
      {
        name: "cotton",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/c/c3/Cotton.png",
  },
  {
    name: "bacon pie",
    level: 18,
    price: 219,
    time: 9180,
    xp: 26,
    needs: [
      {
        name: "bacon",
        quantity: 3,
      },
      {
        name: "egg",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "pie oven",
    image: "https://static.wikia.nocookie.net/hayday/images/b/b4/Bacon_Pie.png",
  },
  {
    name: "syrup",
    level: 18,
    price: 90,
    time: 4560,
    xp: 11,
    needs: [
      {
        name: "sugarcane",
        quantity: 4,
      },
    ],
    source: "sugar mill",
    image: "https://static.wikia.nocookie.net/hayday/images/2/2f/Syrup.png",
  },
  {
    name: "cotton fabric",
    level: 18,
    price: 108,
    time: 1500,
    xp: 13,
    needs: [
      {
        name: "cotton",
        quantity: 3,
      },
    ],
    source: "loom",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/01/Cotton_Fabric.png",
  },
  {
    name: "hamburger",
    level: 18,
    price: 180,
    time: 6120,
    xp: 22,
    needs: [
      {
        name: "bacon",
        quantity: 2,
      },
      {
        name: "bread",
        quantity: 2,
      },
    ],
    source: "BBQ grill",
    image: "https://static.wikia.nocookie.net/hayday/images/a/a4/Hamburger.png",
  },
  {
    name: "raspberry",
    level: 19,
    price: 46,
    time: 64800,
    xp: 9,
    needs: [],
    source: "bush",
    image: "https://static.wikia.nocookie.net/hayday/images/1/1f/Raspberry.png",
  },
  {
    name: "raspberry muffin",
    level: 19,
    price: 140,
    time: 2280,
    xp: 17,
    needs: [
      {
        name: "egg",
        quantity: 1,
      },
      {
        name: "raspberry",
        quantity: 2,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "bakery",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/22/Raspberry_Muffin.png",
  },
  {
    name: "blue wolly hat",
    level: 19,
    price: 111,
    time: 3060,
    xp: 13,
    needs: [
      {
        name: "indigo",
        quantity: 1,
      },
      {
        name: "wool",
        quantity: 1,
      },
    ],
    source: "loom",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/03/Blue_Woolly_Hat.png",
  },
  {
    name: "cotton shirt",
    level: 19,
    price: 241,
    time: 2280,
    xp: 29,
    needs: [
      {
        name: "cotton fabric",
        quantity: 2,
      },
    ],
    source: "sweing machine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/66/Cotton_Shirt.png",
  },
  {
    name: "blue sweater",
    level: 20,
    price: 208,
    time: 9180,
    xp: 25,
    needs: [
      {
        name: "indigo",
        quantity: 2,
      },
      {
        name: "wool",
        quantity: 2,
      },
    ],
    source: "loom",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/37/Blue_Sweater.png",
  },
  {
    name: "carrot cake",
    level: 21,
    price: 165,
    time: 4560,
    xp: 20,
    needs: [
      {
        name: "brown sugar",
        quantity: 1,
      },
      {
        name: "butter",
        quantity: 1,
      },
      {
        name: "carrot",
        quantity: 2,
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/dd/Carrot_Cake.png",
  },
  {
    name: "wooly chaps",
    level: 21,
    price: 309,
    time: 4560,
    xp: 37,
    needs: [
      {
        name: "cotton fabric",
        quantity: 1,
      },
      {
        name: "wool",
        quantity: 3,
      },
    ],
    source: "sewing machine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/22/Wooly_Chaps.png",
  },
  {
    name: "cherry",
    level: 22,
    price: 68,
    time: 97200,
    xp: 13,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/2/20/Cherry.png",
  },
  {
    name: "cream cake",
    level: 23,
    price: 219,
    time: 9180,
    xp: 26,
    needs: [
      {
        name: "cream",
        quantity: 1,
      },
      {
        name: "white sugar",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 5,
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/73/Cream_Cake.png",
  },
  {
    name: "red berry cake",
    level: 23,
    price: 255,
    time: 3060,
    xp: 31,
    needs: [
      {
        name: "cherry",
        quantity: 2,
      },
      {
        name: "egg",
        quantity: 1,
      },
      {
        name: "milk",
        quantity: 1,
      },
      {
        name: "raspberry",
        quantity: 1,
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/69/Red_Berry_Cake.png",
  },
  {
    name: "cheesecake",
    level: 24,
    price: 284,
    time: 12240,
    xp: 34,
    needs: [
      {
        name: "cheese",
        quantity: 1,
      },
      {
        name: "cookie",
        quantity: 1,
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/2d/Cheesecake.png",
  },
  {
    name: "silver ore",
    level: 24,
    price: 18,
    time: 0,
    xp: 5,
    needs: [],
    source: "mine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/97/Silver_Ore.png",
  },
  {
    name: "gold ore",
    level: 24,
    price: 21,
    time: 0,
    xp: 6,
    needs: [],
    source: "mine",
    image: "https://static.wikia.nocookie.net/hayday/images/f/f7/Gold_Ore.png",
  },
  {
    name: "platinum ore",
    level: 24,
    price: 32,
    time: 0,
    xp: 7,
    needs: [],
    source: "mine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b0/Platinum_Ore.png",
  },
  {
    name: "silver bar",
    level: 24,
    price: 147,
    time: 24480,
    xp: 14,
    needs: [
      {
        name: "silver ore",
        quantity: 3,
      },
    ],
    source: "smelter",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/94/Silver_Bar.png",
  },
  {
    name: "chili pepper",
    level: 25,
    price: 36,
    time: 14400,
    xp: 4,
    needs: [
      {
        name: "chili pepper",
        quantity: 1,
      },
    ],
    source: "field",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/ab/Chili_Pepper.png",
  },
  {
    name: "chili popcorn",
    level: 25,
    price: 122,
    time: 6120,
    xp: 15,
    needs: [
      {
        name: "chili pepper",
        quantity: 2,
      },
      {
        name: "corn",
        quantity: 2,
      },
    ],
    source: "popcorn pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7c/Chili_Popcorn.png",
  },
  {
    name: "gold bar",
    level: 25,
    price: 180,
    time: 36720,
    xp: 18,
    needs: [
      {
        name: "gold ore",
        quantity: 3,
      },
    ],
    source: "smelter",
    image: "https://static.wikia.nocookie.net/hayday/images/4/4e/Gold_Bar.png",
  },
  {
    name: "platinum bar",
    level: 25,
    price: 205,
    time: 48960,
    xp: 21,
    needs: [
      {
        name: "platinum ore",
        quantity: 3,
      },
    ],
    source: "smelter",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/d2/Platinum_Bar.png",
  },
  {
    name: "violet dress",
    level: 25,
    price: 327,
    time: 6840,
    xp: 39,
    needs: [
      {
        name: "cotton fabric",
        quantity: 2,
      },
      {
        name: "indigo",
        quantity: 1,
      },
      {
        name: "raspberry",
        quantity: 1,
      },
    ],
    source: "sewing machine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/33/Violet_Dress.png",
  },
  {
    name: "blackberry",
    level: 26,
    price: 82,
    time: 115200,
    xp: 16,
    needs: [],
    source: "bush",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/25/Blackberry.png",
  },
  {
    name: "blackberry muffin",
    level: 26,
    price: 226,
    time: 2280,
    xp: 27,
    needs: [
      {
        name: "blackberry",
        quantity: 2,
      },
      {
        name: "egg",
        quantity: 2,
      },
      {
        name: "wheat",
        quantity: 1,
      },
    ],
    source: "bakery",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c9/Blackberry_Muffin.png",
  },
  {
    name: "carrot juice",
    level: 26,
    price: 46,
    time: 1500,
    xp: 6,
    needs: [
      {
        name: "carrot",
        quantity: 3,
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/e0/Carrot_Juice.png",
  },
  {
    name: "red lure",
    level: 27,
    price: 0,
    time: 4560,
    xp: 1,
    needs: [],
    source: "lure workbench",
    image: "https://static.wikia.nocookie.net/hayday/images/9/96/Red_Lure.png",
  },
  {
    name: "green lure",
    level: 27,
    price: 0,
    time: 3780,
    xp: 2,
    needs: [
      {
        name: "green voucher",
        quantity: 1,
      },
    ],
    source: "lure workbench",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c4/Green_Lure.png",
  },
  {
    name: "blue lure",
    level: 27,
    price: 0,
    time: 3060,
    xp: 3,
    needs: [
      {
        name: "blue voucher",
        quantity: 1,
      },
    ],
    source: "lure workbench",
    image: "https://static.wikia.nocookie.net/hayday/images/1/12/Blue_Lure.png",
  },
  {
    name: "purple lure",
    level: 27,
    price: 0,
    time: 2280,
    xp: 4,
    needs: [
      {
        name: "purple voucher",
        quantity: 1,
      },
    ],
    source: "lure workbench",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/17/Purple_Lure.png",
  },
  {
    name: "gold lure",
    level: 27,
    price: 0,
    time: 1500,
    xp: 5,
    needs: [
      {
        name: "gold voucher",
        quantity: 1,
      },
    ],
    source: "lure workbench",
    image: "https://static.wikia.nocookie.net/hayday/images/a/a8/Gold_Lure.png",
  },
  {
    name: "fish fillet",
    level: 27,
    price: 54,
    time: 0,
    xp: 20,
    needs: [
      {
        name: "red lure",
        quantity: 1,
      },
    ],
    source: "fish",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/63/Fish_Fillet.png",
  },
  {
    name: "fish burger",
    level: 27,
    price: 226,
    time: 6120,
    xp: 27,
    needs: [
      {
        name: "bread",
        quantity: 2,
      },
      {
        name: "chili pepper",
        quantity: 1,
      },
      {
        name: "fish fillet",
        quantity: 2,
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7e/Fish_Burger.png",
  },
  {
    name: "apple pie",
    level: 28,
    price: 270,
    time: 7620,
    xp: 32,
    needs: [
      {
        name: "apple",
        quantity: 3,
      },
      {
        name: "egg",
        quantity: 1,
      },
      {
        name: "syrup",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "pie oven",
    image: "https://static.wikia.nocookie.net/hayday/images/f/fb/Apple_Pie.png",
  },
  {
    name: "fish pie",
    level: 28,
    price: 226,
    time: 6120,
    xp: 27,
    needs: [
      {
        name: "egg",
        quantity: 1,
      },
      {
        name: "fish fillet",
        quantity: 3,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "pie oven",
    image: "https://static.wikia.nocookie.net/hayday/images/e/ec/Fish_Pie.png",
  },
  {
    name: "apple juice",
    level: 28,
    price: 129,
    time: 6120,
    xp: 15,
    needs: [
      {
        name: "apple",
        quantity: 2,
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/41/Apple_Juice.png",
  },
  {
    name: "vanilla ice cream",
    level: 29,
    price: 172,
    time: 6120,
    xp: 20,
    needs: [
      {
        name: "cream",
        quantity: 1,
      },
      {
        name: "milk",
        quantity: 1,
      },
      {
        name: "white sugar",
        quantity: 1,
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/15/Vanilla_Ice_Cream.png",
  },
  {
    name: "tomato",
    level: 30,
    price: 43,
    time: 21600,
    xp: 8,
    needs: [
      {
        name: "tomato",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/9/9d/Tomato.png",
  },
  {
    name: "roasted tomatoes",
    level: 30,
    price: 118,
    time: 4560,
    xp: 14,
    needs: [
      {
        name: "tomato",
        quantity: 2,
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/04/Roasted_Tomatoes.png",
  },
  {
    name: "cherry juice",
    level: 30,
    price: 216,
    time: 7620,
    xp: 26,
    needs: [
      {
        name: "cherry",
        quantity: 2,
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/ab/Cherry_Juice.png",
  },
  {
    name: "tomato juice",
    level: 31,
    price: 162,
    time: 4560,
    xp: 19,
    needs: [
      {
        name: "tomato",
        quantity: 3,
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a5/Tomato_Juice.png",
  },
  {
    name: "berry juice",
    level: 31,
    price: 205,
    time: 9180,
    xp: 24,
    needs: [
      {
        name: "blackberry",
        quantity: 1,
      },
      {
        name: "raspberry",
        quantity: 1,
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/3a/Berry_Juice.png",
  },
  {
    name: "fishing net",
    level: 30,
    price: 0,
    time: 12240,
    xp: 7,
    needs: [],
    source: "net maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a1/Fishing_Net.png",
  },
  {
    name: "mystery net",
    level: 30,
    price: 0,
    time: 9180,
    xp: 7,
    needs: [
      {
        name: "diamond",
        quantity: 5,
      },
    ],
    source: "net maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/17/Mystery_Net.png",
  },
  {
    name: "goat feed",
    level: 32,
    price: 14,
    time: 2040,
    xp: 3,
    needs: [
      {
        name: "carrot",
        quantity: 2,
      },
      {
        name: "corn",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 1,
      },
    ],
    source: "feed mill",
    image: "https://static.wikia.nocookie.net/hayday/images/e/ed/Goat_Feed.png",
  },
  {
    name: "goat milk",
    level: 32,
    price: 64,
    time: 28800,
    xp: 6,
    needs: [
      {
        name: "goat feed",
        quantity: 1,
      },
    ],
    source: "goat",
    image: "https://static.wikia.nocookie.net/hayday/images/4/45/Goat_Milk.png",
  },
  {
    name: "goat cheese",
    level: 33,
    price: 162,
    time: 4560,
    xp: 19,
    needs: [
      {
        name: "goat milk",
        quantity: 2,
      },
    ],
    source: "dairy",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c8/Goat_Cheese.png",
  },
  {
    name: "pizza",
    level: 33,
    price: 190,
    time: 720,
    xp: 23,
    needs: [
      {
        name: "cheese",
        quantity: 1,
      },
      {
        name: "tomato",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "bakery",
    image: "https://static.wikia.nocookie.net/hayday/images/f/f4/Pizza.png",
  },
  {
    name: "coal",
    level: 33,
    price: 10,
    time: 0,
    xp: 3,
    needs: [],
    source: "mine",
    image: "https://static.wikia.nocookie.net/hayday/images/a/a7/Coal.png",
  },
  {
    name: "refined coal",
    level: 33,
    price: 108,
    time: 18360,
    xp: 13,
    needs: [
      {
        name: "coal",
        quantity: 3,
      },
    ],
    source: "smelter",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b5/Refined_Coal.png",
  },
  {
    name: "cherry popsicle",
    level: 33,
    price: 352,
    time: 9180,
    xp: 42,
    needs: [
      {
        name: "cherry juice",
        quantity: 1,
      },
      {
        name: "syrup",
        quantity: 1,
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/48/Cherry_Popsicle.png",
  },
  {
    name: "strawberry",
    level: 34,
    price: 50,
    time: 28800,
    xp: 10,
    needs: [
      {
        name: "strawberry",
        quantity: 1,
      },
    ],
    source: "field",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/6d/Strawberry.png",
  },
  {
    name: "feta pie",
    level: 34,
    price: 223,
    time: 4560,
    xp: 26,
    needs: [
      {
        name: "egg",
        quantity: 1,
      },
      {
        name: "goat cheese",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "pie oven",
    image: "https://static.wikia.nocookie.net/hayday/images/6/61/Feta_Pie.png",
  },
  {
    name: "iron ore",
    level: 34,
    price: 14,
    time: 0,
    xp: 4,
    needs: [],
    source: "mine",
    image: "https://static.wikia.nocookie.net/hayday/images/8/87/Iron_Ore.png",
  },
  {
    name: "iron bar",
    level: 34,
    price: 129,
    time: 21420,
    xp: 15,
    needs: [
      {
        name: "iron ore",
        quantity: 3,
      },
    ],
    source: "smelter",
    image: "https://static.wikia.nocookie.net/hayday/images/6/6c/Iron_Bar.png",
  },
  {
    name: "strawberry ice cream",
    level: 34,
    price: 331,
    time: 12240,
    xp: 40,
    needs: [
      {
        name: "cream",
        quantity: 1,
      },
      {
        name: "milk",
        quantity: 1,
      },
      {
        name: "strawberry",
        quantity: 3,
      },
      {
        name: "white sugar",
        quantity: 1,
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/8d/Strawberry_Ice_Cream.png",
  },
  {
    name: "wheat bundle",
    level: 34,
    price: 50,
    time: 4560,
    xp: 10,
    needs: [
      {
        name: "wheat",
        quantity: 75,
      },
    ],
    source: "feed mill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/5d/Wheat_Bundle.png",
  },
  {
    name: "meat bucket",
    level: 34,
    price: 72,
    time: 2280,
    xp: 15,
    needs: [
      {
        name: "fish fillet",
        quantity: 3,
      },
      {
        name: "carrot",
        quantity: 5,
      },
    ],
    source: "feed mill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/5e/Meat_Bucket.png",
  },
  {
    name: "potato",
    level: 35,
    price: 36,
    time: 13200,
    xp: 7,
    needs: [
      {
        name: "potato",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/c/c2/Potato.png",
  },
  {
    name: "strawberry cake",
    level: 35,
    price: 316,
    time: 9180,
    xp: 38,
    needs: [
      {
        name: "cream",
        quantity: 1,
      },
      {
        name: "strawberry",
        quantity: 2,
      },
      {
        name: "wheat",
        quantity: 3,
      },
      {
        name: "white sugar",
        quantity: 1,
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/1a/Strawberry_Cake.png",
  },
  {
    name: "baked potato",
    level: 35,
    price: 298,
    time: 1740,
    xp: 36,
    needs: [
      {
        name: "cheese",
        quantity: 1,
      },
      {
        name: "chili pepper",
        quantity: 1,
      },
      {
        name: "cream",
        quantity: 1,
      },
      {
        name: "potato",
        quantity: 2,
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/11/Baked_Potato.png",
  },
  {
    name: "apple jam",
    level: 35,
    price: 219,
    time: 18360,
    xp: 26,
    needs: [
      {
        name: "apple",
        quantity: 3,
      },
    ],
    source: "jam maker",
    image: "https://static.wikia.nocookie.net/hayday/images/6/6c/Apple_Jam.png",
  },
  {
    name: "chocolate cake",
    level: 36,
    price: 320,
    time: 6120,
    xp: 38,
    needs: [
      {
        name: "butter",
        quantity: 1,
      },
      {
        name: "cacao",
        quantity: 2,
      },
      {
        name: "brown sugar",
        quantity: 1,
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/87/Chocolate_Cake.png",
  },
  {
    name: "casserole",
    level: 36,
    price: 367,
    time: 6120,
    xp: 44,
    needs: [
      {
        name: "bacon",
        quantity: 2,
      },
      {
        name: "cheese",
        quantity: 1,
      },
      {
        name: "egg",
        quantity: 2,
      },
      {
        name: "potato",
        quantity: 2,
      },
    ],
    source: "pie oven",
    image: "https://static.wikia.nocookie.net/hayday/images/b/b6/Casserole.png",
  },
  {
    name: "cacao",
    level: 36,
    price: 86,
    time: 122400,
    xp: 16,
    needs: [],
    source: "cacao tree",
    image: "https://static.wikia.nocookie.net/hayday/images/6/60/Cacao.png",
  },
  {
    name: "raspberry jam",
    level: 36,
    price: 252,
    time: 21420,
    xp: 30,
    needs: [
      {
        name: "raspberry",
        quantity: 3,
      },
    ],
    source: "jam maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/84/Raspberry_Jam.png",
  },
  {
    name: "spicy pizza",
    level: 37,
    price: 226,
    time: 720,
    xp: 27,
    needs: [
      {
        name: "cheese",
        quantity: 1,
      },
      {
        name: "chili pepper",
        quantity: 1,
      },
      {
        name: "tomato",
        quantity: 1,
      },
      {
        name: "wheat",
        quantity: 2,
      },
    ],
    source: "bakery",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/5c/Spicy_Pizza.png",
  },
  {
    name: "blackberry jam",
    level: 37,
    price: 388,
    time: 24480,
    xp: 46,
    needs: [
      {
        name: "blackberry",
        quantity: 3,
      },
    ],
    source: "jam maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/52/Blackberry_Jam.png",
  },
  {
    name: "potato feta cake",
    level: 38,
    price: 309,
    time: 6120,
    xp: 37,
    needs: [
      {
        name: "egg",
        quantity: 4,
      },
      {
        name: "goat cheese",
        quantity: 1,
      },
      {
        name: "potato",
        quantity: 1,
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7c/Potato_Feta_Cake.png",
  },
  {
    name: "cherry jam",
    level: 38,
    price: 334,
    time: 21420,
    xp: 40,
    needs: [
      {
        name: "cherry",
        quantity: 3,
      },
    ],
    source: "jam maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/53/Cherry_Jam.png",
  },
  {
    name: "bracelet",
    level: 38,
    price: 514,
    time: 5040,
    xp: 61,
    needs: [
      {
        name: "gold bar",
        quantity: 1,
      },
      {
        name: "silver bar",
        quantity: 2,
      },
    ],
    source: "jeweler",
    image: "https://static.wikia.nocookie.net/hayday/images/3/33/Bracelet.png",
  },
  {
    name: "potato bread",
    level: 39,
    price: 284,
    time: 2280,
    xp: 34,
    needs: [
      {
        name: "butter",
        quantity: 1,
      },
      {
        name: "egg",
        quantity: 3,
      },
      {
        name: "potato",
        quantity: 2,
      },
      {
        name: "white sugar",
        quantity: 1,
      },
    ],
    source: "bakery",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/26/Potato_Bread.png",
  },
  {
    name: "shepherds pie",
    level: 39,
    price: 280,
    time: 5100,
    xp: 34,
    needs: [
      {
        name: "bacon",
        quantity: 2,
      },
      {
        name: "carrot",
        quantity: 2,
      },
      {
        name: "potato",
        quantity: 2,
      },
      {
        name: "pumpkin",
        quantity: 2,
      },
    ],
    source: "pie oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c8/Shepherds_Pie.png",
  },
  {
    name: "chocolate ice cream",
    level: 39,
    price: 342,
    time: 7620,
    xp: 41,
    needs: [
      {
        name: "cream",
        quantity: 1,
      },
      {
        name: "cacao",
        quantity: 2,
      },
      {
        name: "milk",
        quantity: 1,
      },
      {
        name: "white sugar",
        quantity: 1,
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/41/Chocolate_Ice_Cream.png",
  },
  {
    name: "honeycomb",
    level: 39,
    price: 68,
    time: 0,
    xp: 8,
    needs: [],
    source: "beehive tree",
    image: "https://static.wikia.nocookie.net/hayday/images/7/7c/Honeycomb.png",
  },
  {
    name: "necklace",
    level: 39,
    price: 727,
    time: 9180,
    xp: 87,
    needs: [
      {
        name: "gold bar",
        quantity: 1,
      },
      {
        name: "platinum bar",
        quantity: 1,
      },
      {
        name: "silver bar",
        quantity: 2,
      },
    ],
    source: "jeweler",
    image: "https://static.wikia.nocookie.net/hayday/images/3/3a/Necklace.png",
  },
  {
    name: "honey",
    level: 39,
    price: 154,
    time: 1020,
    xp: 19,
    needs: [
      {
        name: "honeycomb",
        quantity: 2,
      },
    ],
    source: "honey extractor",
    image: "https://static.wikia.nocookie.net/hayday/images/c/c6/Honey.png",
  },
  {
    name: "honey popcorn",
    level: 40,
    price: 360,
    time: 4560,
    xp: 43,
    needs: [
      {
        name: "corn",
        quantity: 2,
      },
      {
        name: "honey",
        quantity: 2,
      },
    ],
    source: "popcorn pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b1/Honey_Popcorn.png",
  },
  {
    name: "diamond ring",
    level: 40,
    price: 824,
    time: 12240,
    xp: 98,
    needs: [
      {
        name: "diamond",
        quantity: 1,
      },
      {
        name: "gold bar",
        quantity: 2,
      },
      {
        name: "platinum bar",
        quantity: 2,
      },
    ],
    source: "jeweler",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a6/Diamond_Ring.png",
  },
  {
    name: "fish and chips",
    level: 41,
    price: 244,
    time: 4560,
    xp: 29,
    needs: [
      {
        name: "fish fillet",
        quantity: 2,
      },
      {
        name: "potato",
        quantity: 3,
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/ea/Fish_and_Chips.png",
  },
  {
    name: "iron bracelet",
    level: 41,
    price: 658,
    time: 4560,
    xp: 79,
    needs: [
      {
        name: "iron bar",
        quantity: 2,
      },
      {
        name: "refined coal",
        quantity: 2,
      },
      {
        name: "silver bar",
        quantity: 1,
      },
    ],
    source: "jeweler",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/40/Iron_Bracelet.png",
  },
  {
    name: "coffee bean",
    level: 42,
    price: 64,
    time: 90000,
    xp: 12,
    needs: [],
    source: "bush",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/33/Coffee_Bean.png",
  },
  {
    name: "espresso",
    level: 42,
    price: 248,
    time: 240,
    xp: 29,
    needs: [
      {
        name: "coffee bean",
        quantity: "3",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
    ],
    source: "coffee kiosk",
    image: "https://static.wikia.nocookie.net/hayday/images/a/ad/Espresso.png",
  },
  {
    name: "honey apple cake",
    level: 42,
    price: 482,
    time: 10200,
    xp: 57,
    needs: [
      {
        name: "apple",
        quantity: "2",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "honey",
        quantity: "2",
      },
      {
        name: "wheat",
        quantity: "2",
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/16/Honey_Apple_Cake.png",
  },
  {
    name: "caffe latte",
    level: 43,
    price: 219,
    time: 480,
    xp: 26,
    needs: [
      {
        name: "coffee bean",
        quantity: "2",
      },
      {
        name: "milk",
        quantity: "1",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
    ],
    source: "coffee kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/6e/Caff%C3%A8_Latte.png",
  },
  {
    name: "chocolate popcorn",
    level: 44,
    price: 248,
    time: 7620,
    xp: 29,
    needs: [
      {
        name: "cacao",
        quantity: "2",
      },
      {
        name: "corn",
        quantity: "2",
      },
    ],
    source: "popcorn pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/09/Chocolate_Popcorn.png",
  },
  {
    name: "lobster trap",
    level: 44,
    price: 0,
    time: 6120,
    xp: 5,
    needs: [],
    source: "net maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/38/Lobster_Trap.png",
  },
  {
    name: "lobster tail",
    level: 44,
    price: 201,
    time: 21600,
    xp: 24,
    needs: [
      {
        name: "lobster",
        quantity: "1",
      },
    ],
    source: "lobster pool",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/5d/Lobster_Tail.png",
  },
  {
    name: "frutti di mare pizza",
    level: 45,
    price: 403,
    time: 720,
    xp: 48,
    needs: [
      {
        name: "cheese",
        quantity: "1",
      },
      {
        name: "fish fillet",
        quantity: "1",
      },
      {
        name: "lobster tail",
        quantity: "1",
      },
      {
        name: "wheat",
        quantity: "2",
      },
    ],
    source: "bakery",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/5f/Frutti_di_Mare_Pizza.png",
  },
  {
    name: "caffe mocha",
    level: 45,
    price: 291,
    time: 720,
    xp: 35,
    needs: [
      {
        name: "coffee bean",
        quantity: "1",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "cacao",
        quantity: "2",
      },
    ],
    source: "coffee kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c7/Caff%C3%A8_Mocha.png",
  },
  {
    name: "raspberry mocha",
    level: 46,
    price: 259,
    time: 1500,
    xp: 31,
    needs: [
      {
        name: "cacao",
        quantity: "1",
      },
      {
        name: "coffee bean",
        quantity: "1",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "raspberry",
        quantity: "1",
      },
    ],
    source: "coffee kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/5c/Raspberry_Mocha.png",
  },
  {
    name: "lobster soup",
    level: 46,
    price: 612,
    time: 7620,
    xp: 73,
    needs: [
      {
        name: "chili pepper",
        quantity: "2",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "lobster tail",
        quantity: "2",
      },
      {
        name: "tomato",
        quantity: "1",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/59/Lobster_Soup.png",
  },
  {
    name: "hot chocolate",
    level: 47,
    price: 316,
    time: 1260,
    xp: 38,
    needs: [
      {
        name: "cacao",
        quantity: "2",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "milk",
        quantity: "1",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
    ],
    source: "coffee kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b0/Hot_Chocolate.png",
  },
  {
    name: "tomato soup",
    level: 47,
    price: 478,
    time: 4560,
    xp: 57,
    needs: [
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "goat cheese",
        quantity: "1",
      },
      {
        name: "tomato juice",
        quantity: "1",
      },
      {
        name: "tomato",
        quantity: "2",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/dc/Tomato_Soup.png",
  },
  {
    name: "red scarf",
    level: 48,
    price: 288,
    time: 7620,
    xp: 34,
    needs: [
      {
        name: "strawberry",
        quantity: "2",
      },
      {
        name: "wool",
        quantity: "2",
      },
    ],
    source: "loom",
    image: "https://static.wikia.nocookie.net/hayday/images/4/4f/Red_Scarf.png",
  },
  {
    name: "lobster skewer",
    level: 48,
    price: 417,
    time: 2040,
    xp: 50,
    needs: [
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "lobster tail",
        quantity: "1",
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/62/Lobster_Skewer.png",
  },
  {
    name: "beeswax",
    level: 48,
    price: 234,
    time: 2280,
    xp: 28,
    needs: [
      {
        name: "honeycomb",
        quantity: "3",
      },
    ],
    source: "honey extractor",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e4/Beeswax.png",
  },
  {
    name: "strawberry candle",
    level: 48,
    price: 370,
    time: 6120,
    xp: 44,
    needs: [
      {
        name: "beeswax",
        quantity: "1",
      },
      {
        name: "strawberry",
        quantity: "2",
      },
    ],
    source: "candle maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/21/Strawberry_Candle.png",
  },
  {
    name: "asparagus",
    level: 49,
    price: 43,
    time: 21600,
    xp: 8,
    needs: [
      {
        name: "asparagus",
        quantity: 1,
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/4/45/Asparagus.png",
  },
  {
    name: "rustic bouquet",
    level: 49,
    price: 208,
    time: 2280,
    xp: 25,
    needs: [
      {
        name: "wheat",
        quantity: "5",
      },
      {
        name: "indigo",
        quantity: "3",
      },
      {
        name: "cotton",
        quantity: "3",
      },
    ],
    source: "flower shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/93/Rustic_Bouquet.png",
  },
  {
    name: "asparagus quiche",
    level: 49,
    price: 302,
    time: 6120,
    xp: 36,
    needs: [
      {
        name: "asparagus",
        quantity: "2",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "cheese",
        quantity: "1",
      },
      {
        name: "wheat",
        quantity: "4",
      },
    ],
    source: "pie oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/4b/Asparagus_Quiche.png",
  },
  {
    name: "pumpkin soup",
    level: 49,
    price: 392,
    time: 6120,
    xp: 47,
    needs: [
      {
        name: "butter",
        quantity: "1",
      },
      {
        name: "carrot",
        quantity: "2",
      },
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "pumpkin",
        quantity: "3",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/59/Pumpkin_Soup.png",
  },
  {
    name: "strawberry jam",
    level: 50,
    price: 270,
    time: 22920,
    xp: 32,
    needs: [
      {
        name: "strawberry",
        quantity: "3",
      },
    ],
    source: "jam maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/40/Strawberry_Jam.png",
  },
  {
    name: "duck trap",
    level: 50,
    price: 0,
    time: 3060,
    xp: 3,
    needs: [],
    source: "net maker",
    image: "https://static.wikia.nocookie.net/hayday/images/a/a7/Duck_Trap.png",
  },
  {
    name: "duck feather",
    level: 50,
    price: 140,
    time: 10800,
    xp: 17,
    needs: [
      {
        name: "duck",
        quantity: "1",
      },
    ],
    source: "duck salon",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/f9/Duck_Feather.png",
  },
  {
    name: "sesame",
    level: 50,
    price: 18,
    time: 3600,
    xp: 4,
    needs: [
      {
        name: "sesame",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/0/07/Sesame.png",
  },
  {
    name: "sesame ice cream",
    level: 50,
    price: 176,
    time: 6120,
    xp: 21,
    needs: [
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "sesame",
        quantity: "3",
      },
      {
        name: "brown sugar",
        quantity: "1",
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/77/Sesame_Ice_Cream.png",
  },
  {
    name: "caramel apple",
    level: 51,
    price: 255,
    time: 6120,
    xp: 31,
    needs: [
      {
        name: "apple",
        quantity: "1",
      },
      {
        name: "syrup",
        quantity: "2",
      },
    ],
    source: "candy machine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/24/Caramel_Apple.png",
  },
  {
    name: "asparagus soup",
    level: 51,
    price: 255,
    time: 3060,
    xp: 30,
    needs: [
      {
        name: "asparagus",
        quantity: "3",
      },
      {
        name: "cream",
        quantity: "2",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/83/Asparagus_Soup.png",
  },
  {
    name: "pillow",
    level: 51,
    price: 676,
    time: 9180,
    xp: 81,
    needs: [
      {
        name: "cotton fabric",
        quantity: "2",
      },
      {
        name: "duck feather",
        quantity: "3",
      },
    ],
    source: "sewing machine",
    image: "https://static.wikia.nocookie.net/hayday/images/3/36/Pillow.png",
  },
  {
    name: "toffee",
    level: 52,
    price: 176,
    time: 4560,
    xp: 21,
    needs: [
      {
        name: "butter",
        quantity: "1",
      },
      {
        name: "wheat",
        quantity: "1",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
    ],
    source: "candy machine",
    image: "https://static.wikia.nocookie.net/hayday/images/2/29/Toffee.png",
  },
  {
    name: "raspberry candle",
    level: 52,
    price: 360,
    time: 5340,
    xp: 43,
    needs: [
      {
        name: "beeswax",
        quantity: "1",
      },
      {
        name: "raspberry",
        quantity: "2",
      },
    ],
    source: "candle maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/6c/Raspberry_Candle.png",
  },
  {
    name: "pineapple",
    level: 52,
    price: 14,
    time: 1800,
    xp: 3,
    needs: [
      {
        name: "pineapple",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/f/fb/Pineapple.png",
  },
  {
    name: "pineapple juice",
    level: 52,
    price: 68,
    time: 2280,
    xp: 8,
    needs: [
      {
        name: "pineapple",
        quantity: "3",
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/fd/Pineapple_Juice.png",
  },
  {
    name: "fish soup",
    level: 53,
    price: 298,
    time: 9180,
    xp: 35,
    needs: [
      {
        name: "carrot",
        quantity: "1",
      },
      {
        name: "fish fillet",
        quantity: "2",
      },
      {
        name: "milk",
        quantity: "1",
      },
      {
        name: "potato",
        quantity: "3",
      },
    ],
    source: "soup kitchen",
    image: "https://static.wikia.nocookie.net/hayday/images/b/be/Fish_Soup.png",
  },
  {
    name: "lily",
    level: 53,
    price: 21,
    time: 5400,
    xp: 5,
    needs: [
      {
        name: "lily",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/0/0a/Lily.png",
  },
  {
    name: "soy sauce",
    level: 54,
    price: 154,
    time: 9180,
    xp: 19,
    needs: [
      {
        name: "soyabean",
        quantity: "9",
      },
      {
        name: "wheat",
        quantity: "1",
      },
    ],
    source: "sauce maker",
    image: "https://static.wikia.nocookie.net/hayday/images/9/97/Soy_Sauce.png",
  },
  {
    name: "chocolate",
    level: 54,
    price: 460,
    time: 61200,
    xp: 55,
    needs: [
      {
        name: "cacao",
        quantity: "3",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
    ],
    source: "candy machine",
    image: "https://static.wikia.nocookie.net/hayday/images/d/df/Chocolate.png",
  },
  {
    name: "fancy cake",
    level: 54,
    price: 450,
    time: 720,
    xp: 49,
    needs: [
      {
        name: "cream cake",
        quantity: "1",
      },
      {
        name: "lily",
        quantity: "3",
      },
      {
        name: "raspberry",
        quantity: "3",
      },
      {
        name: "gold ore",
        quantity: "1",
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/dd/Fancy_Cake.png",
  },
  {
    name: "rice",
    level: 56,
    price: 18,
    time: 2700,
    xp: 3,
    needs: [
      {
        name: "rice",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/d/da/Rice.png",
  },
  {
    name: "sushi roll",
    level: 56,
    price: 489,
    time: 3060,
    xp: 58,
    needs: [
      {
        name: "fish fillet",
        quantity: "1",
      },
      {
        name: "rice",
        quantity: "15",
      },
      {
        name: "soy sauce",
        quantity: "1",
      },
    ],
    source: "sushi bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/cf/Sushi_Roll.png",
  },
  {
    name: "olive",
    level: 57,
    price: 82,
    time: 86400,
    xp: 17,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/5/5f/Olive.png",
  },
  {
    name: "lollipop",
    level: 57,
    price: 342,
    time: 36720,
    xp: 41,
    needs: [
      {
        name: "cherry",
        quantity: "1",
      },
      {
        name: "strawberry",
        quantity: "2",
      },
      {
        name: "syrup",
        quantity: "1",
      },
    ],
    source: "candy machine",
    image: "https://static.wikia.nocookie.net/hayday/images/5/58/Lollipop.png",
  },
  {
    name: "lettuce",
    level: 58,
    price: 32,
    time: 12600,
    xp: 7,
    needs: [
      {
        name: "lettuce",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/d/da/Lettuce.png",
  },
  {
    name: "feta salad",
    level: 58,
    price: 745,
    time: 4560,
    xp: 89,
    needs: [
      {
        name: "goat cheese",
        quantity: "2",
      },
      {
        name: "lettuce",
        quantity: "3",
      },
      {
        name: "olive",
        quantity: "2",
      },
      {
        name: "roasted tomatoes",
        quantity: "1",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/96/Feta_Salad.png",
  },
  {
    name: "lobster sushi",
    level: 59,
    price: 637,
    time: 3060,
    xp: 76,
    needs: [
      {
        name: "lobster tail",
        quantity: "1",
      },
      {
        name: "rice",
        quantity: "15",
      },
      {
        name: "soy sauce",
        quantity: "1",
      },
    ],
    source: "sushi bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7b/Lobster_Sushi.png",
  },
  {
    name: "blanket",
    level: 59,
    price: 1098,
    time: 10680,
    xp: 131,
    needs: [
      {
        name: "cotton fabric",
        quantity: "3",
      },
      {
        name: "duck feather",
        quantity: "5",
      },
      {
        name: "pumpkin",
        quantity: "1",
      },
    ],
    source: "sewing machine",
    image: "https://static.wikia.nocookie.net/hayday/images/3/3b/Blanket.png",
  },
  {
    name: "jelly beans",
    level: 60,
    price: 684,
    time: 73440,
    xp: 81,
    needs: [
      {
        name: "blackberry jam",
        quantity: "1",
      },
      {
        name: "raspberry jam",
        quantity: "1",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
    ],
    source: "candy machine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/ed/Jelly_Beans.png",
  },
  {
    name: "olive oil",
    level: 60,
    price: 277,
    time: 2280,
    xp: 33,
    needs: [
      {
        name: "olive",
        quantity: "3",
      },
    ],
    source: "sauce maker",
    image: "https://static.wikia.nocookie.net/hayday/images/3/34/Olive_Oil.png",
  },
  {
    name: "garlic",
    level: 60,
    price: 14,
    time: 1800,
    xp: 3,
    needs: [
      {
        name: "garlic",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/c/cc/Garlic.png",
  },
  {
    name: "garlic bread",
    level: 60,
    price: 198,
    time: 720,
    xp: 24,
    needs: [
      {
        name: "bread",
        quantity: "2",
      },
      {
        name: "butter",
        quantity: "1",
      },
      {
        name: "garlic",
        quantity: "4",
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7f/Garlic_Bread.png",
  },
  {
    name: "veggie bagel",
    level: 61,
    price: 532,
    time: 2040,
    xp: 63,
    needs: [
      {
        name: "bread",
        quantity: "2",
      },
      {
        name: "lettuce",
        quantity: "3",
      },
      {
        name: "olive oil",
        quantity: "1",
      },
      {
        name: "tomato",
        quantity: "2",
      },
    ],
    source: "sandwich bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/14/Veggie_Bagel.png",
  },
  {
    name: "mayonnaise",
    level: 62,
    price: 367,
    time: 720,
    xp: 44,
    needs: [
      {
        name: "egg",
        quantity: "4",
      },
      {
        name: "olive oil",
        quantity: "1",
      },
    ],
    source: "sauce maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/4e/Mayonnaise.png",
  },
  {
    name: "blt salad",
    level: 62,
    price: 723,
    time: 5340,
    xp: 86,
    needs: [
      {
        name: "bacon",
        quantity: "2",
      },
      {
        name: "lettuce",
        quantity: "3",
      },
      {
        name: "mayonnaise",
        quantity: "1",
      },
      {
        name: "roasted tomatoes",
        quantity: "1",
      },
    ],
    source: "salad bar",
    image: "https://static.wikia.nocookie.net/hayday/images/e/eb/BLT_Salad.png",
  },
  {
    name: "caramel latte",
    level: 62,
    price: 345,
    time: 720,
    xp: 41,
    needs: [
      {
        name: "coffee bean",
        quantity: "2",
      },
      {
        name: "toffee",
        quantity: "1",
      },
      {
        name: "milk",
        quantity: "1",
      },
    ],
    source: "coffee kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/6a/Caramel_Latte.png",
  },
  {
    name: "peanuts",
    level: 62,
    price: 234,
    time: 0,
    xp: 43,
    needs: [],
    source: "squirrel house",
    image: "https://static.wikia.nocookie.net/hayday/images/9/9b/Peanuts.png",
  },
  {
    name: "sunflower",
    level: 63,
    price: 21,
    time: 5400,
    xp: 5,
    needs: [
      {
        name: "sunflower",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/8/81/Sunflower.png",
  },
  {
    name: "egg sushi",
    level: 63,
    price: 550,
    time: 6120,
    xp: 66,
    needs: [
      {
        name: "brown sugar",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "4",
      },
      {
        name: "rice",
        quantity: "15",
      },
      {
        name: "soy sauce",
        quantity: "1",
      },
    ],
    source: "sushi bar",
    image: "https://static.wikia.nocookie.net/hayday/images/9/9b/Egg_Sushi.png",
  },
  {
    name: "honey peanuts",
    level: 63,
    price: 468,
    time: 2040,
    xp: 64,
    needs: [
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "peanuts",
        quantity: "1",
      },
    ],
    source: "candy machine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/ad/Honey_Peanuts.png",
  },
  {
    name: "seafood salad",
    level: 64,
    price: 763,
    time: 6120,
    xp: 91,
    needs: [
      {
        name: "fish fillet",
        quantity: "1",
      },
      {
        name: "lettuce",
        quantity: "3",
      },
      {
        name: "lobster tail",
        quantity: "1",
      },
      {
        name: "mayonnaise",
        quantity: "1",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/69/Seafood_Salad.png",
  },
  {
    name: "berry smoothie",
    level: 64,
    price: 547,
    time: 3780,
    xp: 65,
    needs: [
      {
        name: "blackberry",
        quantity: "3",
      },
      {
        name: "strawberry",
        quantity: "3",
      },
      {
        name: "raspberry",
        quantity: "3",
      },
    ],
    source: "smoothie mixer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a8/Berry_Smoothie.png",
  },
  {
    name: "snack mix",
    level: 64,
    price: 309,
    time: 2280,
    xp: 50,
    needs: [
      {
        name: "corn",
        quantity: "2",
      },
      {
        name: "sesame",
        quantity: "1",
      },
      {
        name: "peanuts",
        quantity: "1",
      },
    ],
    source: "popcorn pot",
    image: "https://static.wikia.nocookie.net/hayday/images/a/a3/Snack_Mix.png",
  },
  {
    name: "bright bouquet",
    level: 65,
    price: 338,
    time: 1020,
    xp: 40,
    needs: [
      {
        name: "sunflower",
        quantity: "5",
      },
      {
        name: "indigo",
        quantity: "3",
      },
      {
        name: "cotton",
        quantity: "1",
      },
      {
        name: "iron ore",
        quantity: "5",
      },
    ],
    source: "flower shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/52/Bright_Bouquet.png",
  },
  {
    name: "bacon toast",
    level: 65,
    price: 648,
    time: 5100,
    xp: 77,
    needs: [
      {
        name: "bacon",
        quantity: "2",
      },
      {
        name: "bread",
        quantity: "2",
      },
      {
        name: "lettuce",
        quantity: "3",
      },
      {
        name: "mayonnaise",
        quantity: "1",
      },
    ],
    source: "sandwich bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/72/Bacon_Toast.png",
  },
  {
    name: "pineapple cake",
    level: 65,
    price: 259,
    time: 3780,
    xp: 31,
    needs: [
      {
        name: "pineapple",
        quantity: "3",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "cherry",
        quantity: "2",
      },
      {
        name: "wheat",
        quantity: "4",
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/e1/Pineapple_Cake.png",
  },
  {
    name: "chocolate pie",
    level: 65,
    price: 514,
    time: 3780,
    xp: 70,
    needs: [
      {
        name: "wheat",
        quantity: "3",
      },
      {
        name: "cacao",
        quantity: "3",
      },
      {
        name: "egg",
        quantity: "1",
      },
      {
        name: "peanuts",
        quantity: "1",
      },
    ],
    source: "pie oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7c/Chocolate_Pie.png",
  },
  {
    name: "cabbage",
    level: 65,
    price: 18,
    time: 2700,
    xp: 3,
    needs: [
      {
        name: "cabbage",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/c/cc/Cabbage.png",
  },
  {
    name: "cabbage soup",
    level: 65,
    price: 270,
    time: 4560,
    xp: 32,
    needs: [
      {
        name: "cabbage",
        quantity: "3",
      },
      {
        name: "bacon",
        quantity: "2",
      },
      {
        name: "potato",
        quantity: "2",
      },
      {
        name: "carrot",
        quantity: "2",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/34/Cabbage_Soup.png",
  },
  {
    name: "lemon",
    level: 66,
    price: 93,
    time: 104400,
    xp: 18,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/3/35/Lemon.png",
  },
  {
    name: "lemon curd",
    level: 66,
    price: 378,
    time: 1260,
    xp: 45,
    needs: [
      {
        name: "butter",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "lemon",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
    ],
    source: "sauce maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/ea/Lemon_Curd.png",
  },
  {
    name: "olive dip",
    level: 66,
    price: 468,
    time: 2280,
    xp: 56,
    needs: [
      {
        name: "olive",
        quantity: "3",
      },
      {
        name: "bread",
        quantity: "2",
      },
      {
        name: "lemon",
        quantity: "1",
      },
      {
        name: "fish fillet",
        quantity: "1",
      },
    ],
    source: "sauce maker",
    image: "https://static.wikia.nocookie.net/hayday/images/3/32/Olive_Dip.png",
  },
  {
    name: "egg sandwich",
    level: 66,
    price: 583,
    time: 4080,
    xp: 69,
    needs: [
      {
        name: "bread",
        quantity: "2",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "lettuce",
        quantity: "3",
      },
      {
        name: "mayonnaise",
        quantity: "1",
      },
    ],
    source: "sandwich bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/81/Egg_Sandwich.png",
  },
  {
    name: "green smoothie",
    level: 66,
    price: 320,
    time: 2280,
    xp: 38,
    needs: [
      {
        name: "apple",
        quantity: "1",
      },
      {
        name: "lemon",
        quantity: "1",
      },
      {
        name: "lettuce",
        quantity: "5",
      },
    ],
    source: "smoothie mixer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/f6/Green_Smoothie.png",
  },
  {
    name: "fresh pasta",
    level: 67,
    price: 43,
    time: 720,
    xp: 5,
    needs: [
      {
        name: "egg",
        quantity: "1",
      },
      {
        name: "wheat",
        quantity: "2",
      },
    ],
    source: "pasta maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/93/Fresh_Pasta.png",
  },
  {
    name: "pasta salad",
    level: 67,
    price: 759,
    time: 7620,
    xp: 90,
    needs: [
      {
        name: "fresh pasta",
        quantity: "4",
      },
      {
        name: "lemon",
        quantity: "2",
      },
      {
        name: "olive oil",
        quantity: "1",
      },
      {
        name: "tomato",
        quantity: "2",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/18/Pasta_Salad.png",
  },
  {
    name: "lemon pie",
    level: 67,
    price: 446,
    time: 6840,
    xp: 53,
    needs: [
      {
        name: "egg",
        quantity: "1",
      },
      {
        name: "lemon curd",
        quantity: "1",
      },
      {
        name: "wheat",
        quantity: "2",
      },
    ],
    source: "pie oven",
    image: "https://static.wikia.nocookie.net/hayday/images/8/85/Lemon_Pie.png",
  },
  {
    name: "grilled asparagus",
    level: 67,
    price: 486,
    time: 3780,
    xp: 58,
    needs: [
      {
        name: "asparagus",
        quantity: "5",
      },
      {
        name: "bacon",
        quantity: "3",
      },
      {
        name: "lemon",
        quantity: "1",
      },
    ],
    source: "BBQ grill",
    image: "https://static.wikia.nocookie.net/hayday/images/c/cf/Grilled_Asparagus.png",
  },
  {
    name: "onion",
    level: 68,
    price: 39,
    time: 18000,
    xp: 8,
    needs: [
      {
        name: "onion",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/5/51/Onion.png",
  },
  {
    name: "grilled onion",
    level: 68,
    price: 190,
    time: 3060,
    xp: 23,
    needs: [
      {
        name: "butter",
        quantity: "1",
      },
      {
        name: "onion",
        quantity: "2",
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/70/Grilled_Onion.png",
  },
  {
    name: "lemon essential oil",
    level: 68,
    price: 228,
    time: 480,
    xp: 34,
    needs: [
      {
        name: "lemon",
        quantity: "3",
      }
    ],
    source: "essential oils lab",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/9a/Lemon_Essential_Oil.png",
  },
  {
    name: "lemon cake",
    level: 68,
    price: 896,
    time: 7620,
    xp: 107,
    needs: [
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "lemon curd",
        quantity: "2",
      },
      {
        name: "wheat",
        quantity: "3",
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/6c/Lemon_Cake.png",
  },
  {
    name: "peanut butter milkshake",
    level: 68,
    price: 619,
    time: 5100,
    xp: 86,
    needs: [
      {
        name: "peanuts",
        quantity: "1",
      },
      {
        name: "milk",
        quantity: "1",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "cacao",
        quantity: "3",
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b7/Peanut_Butter_Milkshake.png",
  },
  {
    name: "tomato sauce",
    level: 69,
    price: 230,
    time: 1500,
    xp: 27,
    needs: [
      {
        name: "brown sugar",
        quantity: "1",
      },
      {
        name: "lemon",
        quantity: "1",
      },
      {
        name: "tomato",
        quantity: "2",
      },
    ],
    source: "sauce maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/09/Tomato_Sauce.png",
  },
  {
    name: "honey toast",
    level: 69,
    price: 255,
    time: 3060,
    xp: 31,
    needs: [
      {
        name: "bread",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "1",
      },
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "milk",
        quantity: "1",
      },
    ],
    source: "sandwich bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/6d/Honey_Toast.png",
  },
  {
    name: "fried rice",
    level: 69,
    price: 205,
    time: 3060,
    xp: 24,
    needs: [
      {
        name: "rice",
        quantity: "5",
      },
      {
        name: "egg",
        quantity: "5",
      },
    ],
    source: "wok kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/61/Fried_Rice.png",
  },
  {
    name: "yogurt smoothie",
    level: 70,
    price: 349,
    time: 3060,
    xp: 42,
    needs: [
      {
        name: "cherry",
        quantity: "1",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "raspberry",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
    ],
    source: "smoothie mixer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/d2/Yogurt_Smoothie.png",
  },
  {
    name: "cloche hat",
    level: 70,
    price: 468,
    time: 6120,
    xp: 56,
    needs: [
      {
        name: "strawberry",
        quantity: "2",
      },
      {
        name: "wool",
        quantity: "6",
      },
    ],
    source: "hat maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/1e/Cloche_Hat.png",
  },
  {
    name: "cucumber",
    level: 70,
    price: 14,
    time: 2100,
    xp: 3,
    needs: [
      {
        name: "cucumber",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/5/59/Cucumber.png",
  },
  {
    name: "cucumber smoothie",
    level: 70,
    price: 266,
    time: 2040,
    xp: 32,
    needs: [
      {
        name: "cucumber",
        quantity: "3",
      },
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "pineapple",
        quantity: "3",
      },
    ],
    source: "smoothie mixer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/57/Cucumber_Smoothie.png",
  },
  {
    name: "orange",
    level: 71,
    price: 97,
    time: 111600,
    xp: 19,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/4/43/Orange.png",
  },
  {
    name: "flower shawl",
    level: 71,
    price: 295,
    time: 4560,
    xp: 35,
    needs: [
      {
        name: "wool",
        quantity: "2",
      },
      {
        name: "sunflower",
        quantity: "3",
      },
      {
        name: "blackberry",
        quantity: "1",
      },
    ],
    source: "loom",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/66/Flower_Shawl.png",
  },
  {
    name: "orange juice",
    level: 71,
    price: 234,
    time: 6120,
    xp: 28,
    needs: [
      {
        name: "orange",
        quantity: "2",
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7e/Orange_Juice.png",
  },
  {
    name: "peanut butter and jelly sandwich",
    level: 71,
    price: 601,
    time: 1260,
    xp: 80,
    needs: [
      {
        name: "bread",
        quantity: "2",
      },
      {
        name: "raspberry jam",
        quantity: "1",
      },
      {
        name: "peanuts",
        quantity: "1",
      },
    ],
    source: "sandwich bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/69/Peanut_Butter_and_Jelly_Sandwich.png",
  },
  {
    name: "onion soup",
    level: 72,
    price: 327,
    time: 7620,
    xp: 39,
    needs: [
      {
        name: "bread",
        quantity: "2",
      },
      {
        name: "cheese",
        quantity: "1",
      },
      {
        name: "onion",
        quantity: "3",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/05/Onion_Soup.png",
  },
  {
    name: "lemon candle",
    level: 72,
    price: 457,
    time: 6840,
    xp: 55,
    needs: [
      {
        name: "beeswax",
        quantity: "1",
      },
      {
        name: "lemon",
        quantity: "2",
      },
    ],
    source: "candle maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/8a/Lemon_Candle.png",
  },
  {
    name: "top hat",
    level: 72,
    price: 619,
    time: 10680,
    xp: 74,
    needs: [
      {
        name: "cotton fabric",
        quantity: "3",
      },
      {
        name: "duck feather",
        quantity: "1",
      },
      {
        name: "refined coal",
        quantity: "1",
      },
    ],
    source: "hat maker",
    image: "https://static.wikia.nocookie.net/hayday/images/8/8d/Top_Hat.png",
  },
  {
    name: "gnocchi",
    level: 72,
    price: 475,
    time: 4080,
    xp: 57,
    needs: [
      {
        name: "potato",
        quantity: "4",
      },
      {
        name: "tomato",
        quantity: "3",
      },
      {
        name: "wheat",
        quantity: "2",
      },
      {
        name: "butter",
        quantity: "2",
      },
    ],
    source: "pasta kitchen",
    image: "https://static.wikia.nocookie.net/hayday/images/8/81/Gnocchi.png",
  },
  {
    name: "beetroot",
    level: 72,
    price: 14,
    time: 2400,
    xp: 3,
    needs: [
      {
        name: "beetroot",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/5/56/Beetroot.png",
  },
  {
    name: "winter veggies",
    level: 72,
    price: 198,
    time: 1260,
    xp: 24,
    needs: [
      {
        name: "beetroot",
        quantity: "2",
      },
      {
        name: "carrot",
        quantity: "3",
      },
      {
        name: "potato",
        quantity: "2",
      },
      {
        name: "pumpkin",
        quantity: "2",
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/08/Winter_Veggies.png",
  },
  {
    name: "rice noodles",
    level: 73,
    price: 100,
    time: 1020,
    xp: 12,
    needs: [
      {
        name: "rice",
        quantity: "5",
      },
    ],
    source: "pasta maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/20/Rice_Noodles.png",
  },
  {
    name: "noodle soup",
    level: 73,
    price: 432,
    time: 6120,
    xp: 52,
    needs: [
      {
        name: "carrot",
        quantity: "2",
      },
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "rice noodles",
        quantity: "3",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/86/Noodle_Soup.png",
  },
  {
    name: "gracious bouquet",
    level: 73,
    price: 500,
    time: 2040,
    xp: 60,
    needs: [
      {
        name: "cotton fabric",
        quantity: "1",
      },
      {
        name: "lily",
        quantity: "5",
      },
      {
        name: "gold ore",
        quantity: "1",
      },
      {
        name: "diamond",
        quantity: "1",
      },
    ],
    source: "flower shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/82/Gracious_Bouquet.png",
  },
  {
    name: "bell pepper",
    level: 74,
    price: 36,
    time: 16200,
    xp: 7,
    needs: [
      {
        name: "bell pepper",
        quantity: "1",
      },
    ],
    source: "field",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/e2/Bell_Pepper.png",
  },
  {
    name: "marmalade",
    level: 74,
    price: 457,
    time: 25980,
    xp: 54,
    needs: [
      {
        name: "orange",
        quantity: "3",
      },
    ],
    source: "jam maker",
    image: "https://static.wikia.nocookie.net/hayday/images/e/ed/Marmalade.png",
  },
  {
    name: "sun hat",
    level: 74,
    price: 558,
    time: 7620,
    xp: 66,
    needs: [
      {
        name: "duck feather",
        quantity: "2",
      },
      {
        name: "indigo",
        quantity: "1",
      },
      {
        name: "raspberry",
        quantity: "1",
      },
      {
        name: "wool",
        quantity: "3",
      },
    ],
    source: "hat maker",
    image: "https://static.wikia.nocookie.net/hayday/images/4/4c/Sun_Hat.png",
  },
  {
    name: "veggie lasagna",
    level: 74,
    price: 532,
    time: 5100,
    xp: 63,
    needs: [
      {
        name: "tomato sauce",
        quantity: "1",
      },
      {
        name: "fresh pasta",
        quantity: "3",
      },
      {
        name: "carrot",
        quantity: "1",
      },
      {
        name: "cheese",
        quantity: "1",
      },
    ],
    source: "pasta kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/d1/Veggie_Lasagna.png",
  },
  {
    name: "veggie platter",
    level: 74,
    price: 266,
    time: 6120,
    xp: 32,
    needs: [
      {
        name: "bell pepper",
        quantity: "2",
      },
      {
        name: "carrot",
        quantity: "2",
      },
      {
        name: "tomato",
        quantity: "2",
      },
      {
        name: "cream",
        quantity: "1",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7d/Veggie_Platter.png",
  },
  {
    name: "hot dog",
    level: 75,
    price: 370,
    time: 1500,
    xp: 44,
    needs: [
      {
        name: "bacon",
        quantity: "2",
      },
      {
        name: "bread",
        quantity: "1",
      },
      {
        name: "tomato sauce",
        quantity: "1",
      },
    ],
    source: "hot dog stand",
    image: "https://static.wikia.nocookie.net/hayday/images/8/82/Hot_Dog.png",
  },
  {
    name: "coleslaw",
    level: 75,
    price: 468,
    time: 3780,
    xp: 56,
    needs: [
      {
        name: "cabbage",
        quantity: "3",
      },
      {
        name: "carrot",
        quantity: "2",
      },
      {
        name: "mayonnaise",
        quantity: "1",
      },
    ],
    source: "salad bar",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e1/Coleslaw.png",
  },
  {
    name: "cotton candy",
    level: 75,
    price: 226,
    time: 1500,
    xp: 27,
    needs: [
      {
        name: "white sugar",
        quantity: "3",
      },
      {
        name: "strawberry",
        quantity: "1",
      },
    ],
    source: "candy machine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/67/Cotton_Candy.png",
  },
  {
    name: "peach",
    level: 76,
    price: 100,
    time: 108000,
    xp: 20,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e2/Peach.png",
  },
  {
    name: "peach tart",
    level: 76,
    price: 435,
    time: 7620,
    xp: 52,
    needs: [
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "peach",
        quantity: "3",
      },
      {
        name: "wheat",
        quantity: "2",
      },
    ],
    source: "pie oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/07/Peach_Tart.png",
  },
  {
    name: "tofu dog",
    level: 76,
    price: 367,
    time: 2280,
    xp: 44,
    needs: [
      {
        name: "lettuce",
        quantity: "3",
      },
      {
        name: "soyabean",
        quantity: "6",
      },
      {
        name: "tomato",
        quantity: "4",
      },
    ],
    source: "hot dog stand",
    image: "https://static.wikia.nocookie.net/hayday/images/f/fa/Tofu_Dog.png",
  },
  {
    name: "big sushi roll",
    level: 76,
    price: 648,
    time: 4560,
    xp: 77,
    needs: [
      {
        name: "lettuce",
        quantity: "5",
      },
      {
        name: "rice",
        quantity: "20",
      },
      {
        name: "fish fillet",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "3",
      },
    ],
    source: "sushi bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a9/Big_Sushi_Roll.png",
  },
  {
    name: "beetroot salad",
    level: 76,
    price: 234,
    time: 2280,
    xp: 28,
    needs: [
      {
        name: "beetroot",
        quantity: "3",
      },
      {
        name: "goat cheese",
        quantity: "1",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/ff/Beetroot_Salad.png",
  },
  {
    name: "plain donut",
    level: 76,
    price: 129,
    time: 720,
    xp: 15,
    needs: [
      {
        name: "milk",
        quantity: "1",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "1",
      },
      {
        name: "wheat",
        quantity: "3",
      },
    ],
    source: "donut maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/08/Plain_Donut.png",
  },
  {
    name: "colourful omelet",
    level: 77,
    price: 136,
    time: 3060,
    xp: 16,
    needs: [
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "carrot",
        quantity: "2",
      },
      {
        name: "bell pepper",
        quantity: "1",
      },
      {
        name: "cabbage",
        quantity: "1",
      },
    ],
    source: "omelet station",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/65/Colourful_Omelet.png",
  },
  {
    name: "spring omelet",
    level: 77,
    price: 230,
    time: 2040,
    xp: 27,
    needs: [
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "asparagus",
        quantity: "2",
      },
      {
        name: "butter",
        quantity: "1",
      },
    ],
    source: "omelet station",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/f2/Spring_Omelet.png",
  },
  {
    name: "salsa",
    level: 77,
    price: 252,
    time: 1020,
    xp: 30,
    needs: [
      {
        name: "chili pepper",
        quantity: "2",
      },
      {
        name: "onion",
        quantity: "2",
      },
      {
        name: "tomato",
        quantity: "2",
      },
    ],
    source: "sauce maker",
    image: "https://static.wikia.nocookie.net/hayday/images/1/1e/Salsa.png",
  },
  {
    name: "taco",
    level: 77,
    price: 396,
    time: 2280,
    xp: 47,
    needs: [
      {
        name: "corn bread",
        quantity: "1",
      },
      {
        name: "bacon",
        quantity: "1",
      },
      {
        name: "salsa",
        quantity: "1",
      },
    ],
    source: "taco kitchen",
    image: "https://static.wikia.nocookie.net/hayday/images/4/4d/Taco.png",
  },
  {
    name: "summer rolls",
    level: 78,
    price: 316,
    time: 3060,
    xp: 38,
    needs: [
      {
        name: "rice noodles",
        quantity: "2",
      },
      {
        name: "carrot",
        quantity: "2",
      },
      {
        name: "lettuce",
        quantity: "1",
      },
      {
        name: "chili pepper",
        quantity: "1",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/74/Summer_Rolls.png",
  },
  {
    name: "orange sorbet",
    level: 78,
    price: 399,
    time: 10680,
    xp: 48,
    needs: [
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "orange",
        quantity: "2",
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/11/Orange_Sorbet.png",
  },
  {
    name: "corn dog",
    level: 78,
    price: 529,
    time: 3060,
    xp: 63,
    needs: [
      {
        name: "bacon",
        quantity: "4",
      },
      {
        name: "corn",
        quantity: "4",
      },
      {
        name: "olive oil",
        quantity: "1",
      },
    ],
    source: "hot dog stand",
    image: "https://static.wikia.nocookie.net/hayday/images/1/15/Corn_Dog.png",
  },
  {
    name: "potato soup",
    level: 78,
    price: 255,
    time: 7620,
    xp: 31,
    needs: [
      {
        name: "milk",
        quantity: "1",
      },
      {
        name: "onion",
        quantity: "2",
      },
      {
        name: "potato",
        quantity: "3",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/ab/Potato_Soup.png",
  },
  {
    name: "ginger",
    level: 78,
    price: 28,
    time: 9000,
    xp: 6,
    needs: [
      {
        name: "ginger",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/8/85/Ginger.png",
  },
  {
    name: "sesame brittle",
    level: 78,
    price: 270,
    time: 3060,
    xp: 32,
    needs: [
      {
        name: "sesame",
        quantity: "3",
      },
      {
        name: "ginger",
        quantity: "1",
      },
      {
        name: "honey",
        quantity: "1",
      },
    ],
    source: "candy machine",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/65/Sesame_Brittle.png",
  },
  {
    name: "affogato",
    level: 78,
    price: 435,
    time: 1020,
    xp: 56,
    needs: [
      {
        name: "vanilla ice cream",
        quantity: "1",
      },
      {
        name: "espresso",
        quantity: "1",
      },
    ],
    source: "ice cream maker",
    image: "https://static.wikia.nocookie.net/hayday/images/f/fc/Affogato.png",
  },
  {
    name: "fish taco",
    level: 79,
    price: 392,
    time: 4560,
    xp: 47,
    needs: [
      {
        name: "chili pepper",
        quantity: "2",
      },
      {
        name: "corn bread",
        quantity: "2",
      },
      {
        name: "fish fillet",
        quantity: "1",
      },
      {
        name: "lemon",
        quantity: "1",
      },
    ],
    source: "taco kitchen",
    image: "https://static.wikia.nocookie.net/hayday/images/d/d5/Fish_Taco.png",
  },
  {
    name: "cheese omelet",
    level: 79,
    price: 464,
    time: 4560,
    xp: 55,
    needs: [
      {
        name: "egg",
        quantity: "3",
      },
      {
        name: "cheese",
        quantity: "2",
      },
      {
        name: "tomato",
        quantity: "2",
      },
      {
        name: "onion",
        quantity: "1",
      },
    ],
    source: "omelet station",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c2/Cheese_Omelet.png",
  },
  {
    name: "peach jam",
    level: 79,
    price: 464,
    time: 24480,
    xp: 55,
    needs: [
      {
        name: "peach",
        quantity: "3",
      },
    ],
    source: "jam maker",
    image: "https://static.wikia.nocookie.net/hayday/images/b/be/Peach_Jam.png",
  },
  {
    name: "lobster pasta",
    level: 79,
    price: 637,
    time: 6120,
    xp: 76,
    needs: [
      {
        name: "lobster tail",
        quantity: "1",
      },
      {
        name: "cream",
        quantity: "2",
      },
      {
        name: "fresh pasta",
        quantity: "3",
      },
      {
        name: "tomato",
        quantity: "4",
      },
    ],
    source: "pasta kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/5a/Lobster_Pasta.png",
  },
  {
    name: "cucumber sandwich",
    level: 79,
    price: 464,
    time: 1740,
    xp: 55,
    needs: [
      {
        name: "cucumber",
        quantity: "2",
      },
      {
        name: "mayonnaise",
        quantity: "1",
      },
      {
        name: "bread",
        quantity: "2",
      },
    ],
    source: "sandwich bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b4/Cucumber_Sandwich.png",
  },
  {
    name: "spicy fish",
    level: 79,
    price: 543,
    time: 4560,
    xp: 65,
    needs: [
      {
        name: "fish fillet",
        quantity: "1",
      },
      {
        name: "chili pepper",
        quantity: "3",
      },
      {
        name: "garlic",
        quantity: "5",
      },
      {
        name: "olive oil",
        quantity: "1",
      },
    ],
    source: "wok kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/71/Spicy_Fish.png",
  },
  {
    name: "sprinkled donut",
    level: 79,
    price: 313,
    time: 1020,
    xp: 37,
    needs: [
      {
        name: "plain donut",
        quantity: "1",
      },
      {
        name: "cacao",
        quantity: "2",
      },
    ],
    source: "donut maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/40/Sprinkled_Donut.png",
  },
  {
    name: "tea leaf",
    level: 80,
    price: 43,
    time: 23400,
    xp: 9,
    needs: [
      {
        name: "tea leaf",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e3/Tea_Leaf.png",
  },
  {
    name: "ginger essential oil",
    level: 80,
    price: 162,
    time: 1020,
    xp: 19,
    needs: [
      {
        name: "ginger",
        quantity: "5",
      }
    ],
    source: "essential oils lab",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/47/Ginger_Essential_Oil.png",
  },
  {
    name: "green tea",
    level: 80,
    price: 241,
    time: 1500,
    xp: 29,
    needs: [
      {
        name: "tea leaf",
        quantity: "5",
      },
    ],
    source: "tea stand",
    image: "https://static.wikia.nocookie.net/hayday/images/8/8f/Green_Tea.png",
  },
  {
    name: "onion dog",
    level: 80,
    price: 306,
    time: 3780,
    xp: 36,
    needs: [
      {
        name: "bacon",
        quantity: "2",
      },
      {
        name: "bread",
        quantity: "1",
      },
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "onion",
        quantity: "3",
      },
    ],
    source: "hot dog stand",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e8/Onion_Dog.png",
  },
  {
    name: "stuffed peppers",
    level: 80,
    price: 352,
    time: 1020,
    xp: 42,
    needs: [
      {
        name: "bell pepper",
        quantity: "3",
      },
      {
        name: "bacon",
        quantity: "1",
      },
      {
        name: "rice",
        quantity: "3",
      },
      {
        name: "cheese",
        quantity: "1",
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/06/Stuffed_Peppers.png",
  },
  {
    name: "milk tea",
    level: 81,
    price: 190,
    time: 2280,
    xp: 23,
    needs: [
      {
        name: "milk",
        quantity: "1",
      },
      {
        name: "tea leaf",
        quantity: "3",
      },
    ],
    source: "tea stand",
    image: "https://static.wikia.nocookie.net/hayday/images/d/d5/Milk_Tea.png",
  },
  {
    name: "bell pepper soup",
    level: 81,
    price: 439,
    time: 3060,
    xp: 52,
    needs: [
      {
        name: "bread",
        quantity: "1",
      },
      {
        name: "olive oil",
        quantity: "1",
      },
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "bell pepper",
        quantity: "2",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/81/Bell_Pepper_Soup.png",
  },
  {
    name: "chocolate fondue",
    level: 81,
    price: 626,
    time: 1260,
    xp: 74,
    needs: [
      {
        name: "chocolate",
        quantity: "1",
      },
      {
        name: "strawberry",
        quantity: "3",
      },
    ],
    source: "fondue pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/ce/Chocolate_Fondue.png",
  },
  {
    name: "peony",
    level: 82,
    price: 36,
    time: 14400,
    xp: 7,
    needs: [
      {
        name: "peony",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/5/50/Peony.png",
  },
  {
    name: "quesadilla",
    level: 82,
    price: 241,
    time: 3060,
    xp: 29,
    needs: [
      {
        name: "cheese",
        quantity: "1",
      },
      {
        name: "chili pepper",
        quantity: "2",
      },
      {
        name: "wheat",
        quantity: "4",
      },
    ],
    source: "taco kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/f1/Quesadilla.png",
  },
  {
    name: "fruit salad",
    level: 82,
    price: 597,
    time: 6120,
    xp: 71,
    needs: [
      {
        name: "blackberry",
        quantity: "2",
      },
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "orange",
        quantity: "1",
      },
      {
        name: "strawberry",
        quantity: "3",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/9e/Fruit_Salad.png",
  },
  {
    name: "crunchy donut",
    level: 82,
    price: 594,
    time: 1500,
    xp: 71,
    needs: [
      {
        name: "plain donut",
        quantity: "1",
      },
      {
        name: "cacao",
        quantity: "1",
      },
      {
        name: "peanuts",
        quantity: "1",
      },
    ],
    source: "donut maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/46/Crunchy_Donut.png",
  },
  {
    name: "pasta carbonara",
    level: 83,
    price: 410,
    time: 7620,
    xp: 49,
    needs: [
      {
        name: "fresh pasta",
        quantity: "3",
      },
      {
        name: "bacon",
        quantity: "2",
      },
      {
        name: "egg",
        quantity: "1",
      },
      {
        name: "cheese",
        quantity: "1",
      },
    ],
    source: "pasta kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/cd/Pasta_Carbonara.png",
  },
  {
    name: "peach ice cream",
    level: 83,
    price: 450,
    time: 9180,
    xp: 54,
    needs: [
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "peach",
        quantity: "2",
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/72/Peach_Ice_Cream.png",
  },
  {
    name: "honey tea",
    level: 83,
    price: 313,
    time: 2040,
    xp: 37,
    needs: [
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "tea leaf",
        quantity: "3",
      },
    ],
    source: "tea stand",
    image: "https://static.wikia.nocookie.net/hayday/images/b/b8/Honey_Tea.png",
  },
  {
    name: "broccoli",
    level: 83,
    price: 21,
    time: 5400,
    xp: 4,
    needs: [
      {
        name: "broccoli",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/f/f1/Broccoli.png",
  },
  {
    name: "broccoli pasta",
    level: 83,
    price: 345,
    time: 3060,
    xp: 41,
    needs: [
      {
        name: "broccoli",
        quantity: "3",
      },
      {
        name: "cheese",
        quantity: "1",
      },
      {
        name: "fresh pasta",
        quantity: "3",
      },
    ],
    source: "pasta kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a8/Broccoli_Pasta.png",
  },
  {
    name: "summer salad",
    level: 84,
    price: 554,
    time: 9180,
    xp: 66,
    needs: [
      {
        name: "goat cheese",
        quantity: "1",
      },
      {
        name: "onion",
        quantity: "3",
      },
      {
        name: "peach",
        quantity: "1",
      },
      {
        name: "tomato",
        quantity: "3",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/30/Summer_Salad.png",
  },
  {
    name: "grapes",
    level: 84,
    price: 32,
    time: 10800,
    xp: 6,
    needs: [
      {
        name: "grapes",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/3/39/Grapes.png",
  },
  {
    name: "grape juice",
    level: 84,
    price: 104,
    time: 7620,
    xp: 13,
    needs: [
      {
        name: "grapes",
        quantity: "2",
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/24/Grape_Juice.png",
  },
  {
    name: "onion melt",
    level: 84,
    price: 471,
    time: 4560,
    xp: 50,
    needs: [
      {
        name: "corn bread",
        quantity: "2",
      },
      {
        name: "onion",
        quantity: "3",
      },
      {
        name: "cheese",
        quantity: "1",
      },
    ],
    source: "sandwich bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/54/Onion_Melt.png",
  },
  {
    name: "honey soap",
    level: 84,
    price: 327,
    time: 3060,
    xp: 39,
    needs: [
      {
        name: "goat milk",
        quantity: "1",
      },
      {
        name: "cacao",
        quantity: "2",
      },
      {
        name: "honeycomb",
        quantity: "1",
      },
    ],
    source: "bath kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/8f/Honey_Soap.png",
  },
  {
    name: "lemon lotion",
    level: 84,
    price: 403,
    time: 3780,
    xp: 48,
    needs: [
      {
        name: "olive oil",
        quantity: "1",
      },
      {
        name: "lemon",
        quantity: "1",
      },
    ],
    source: "bath kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/8f/Lemon_Lotion.png",
  },
  {
    name: "colorful candles",
    level: 84,
    price: 324,
    time: 5580,
    xp: 39,
    needs: [
      {
        name: "beeswax",
        quantity: "1",
      },
      {
        name: "raspberry",
        quantity: "1",
      },
      {
        name: "carrot",
        quantity: "1",
      },
    ],
    source: "candle maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/19/Colorful_Candles.png",
  },
  {
    name: "mint",
    level: 85,
    price: 32,
    time: 10800,
    xp: 6,
    needs: [
      {
        name: "mint",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/1/13/Mint.png",
  },
  {
    name: "grape jam",
    level: 85,
    price: 162,
    time: 19860,
    xp: 19,
    needs: [
      {
        name: "grapes",
        quantity: "3",
      },
    ],
    source: "jam maker",
    image: "https://static.wikia.nocookie.net/hayday/images/f/fa/Grape_Jam.png",
  },
  {
    name: "mint essential oil",
    level: 85,
    price: 172,
    time: 720,
    xp: 20,
    needs: [
      {
        name: "mint",
        quantity: "3",
      }
    ],
    source: "essential oils lab",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/33/Mint_Essential_Oil.png",
  },
  {
    name: "mint ice cream",
    level: 85,
    price: 288,
    time: 6840,
    xp: 34,
    needs: [
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
      {
        name: "mint",
        quantity: "2",
      },
      {
        name: "cacao",
        quantity: "1",
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b9/Mint_Ice_Cream.png",
  },
  {
    name: "flower crown",
    level: 86,
    price: 331,
    time: 6120,
    xp: 40,
    needs: [
      {
        name: "peony",
        quantity: "5",
      },
      {
        name: "cotton",
        quantity: "4",
      },
    ],
    source: "hat maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/ce/Flower_Crown.png",
  },
  {
    name: "lemon tea",
    level: 86,
    price: 241,
    time: 1020,
    xp: 29,
    needs: [
      {
        name: "lemon",
        quantity: "1",
      },
      {
        name: "tea leaf",
        quantity: "3",
      },
    ],
    source: "tea stand",
    image: "https://static.wikia.nocookie.net/hayday/images/2/28/Lemon_Tea.png",
  },
  {
    name: "bacon fondue",
    level: 86,
    price: 507,
    time: 1500,
    xp: 60,
    needs: [
      {
        name: "bacon",
        quantity: "3",
      },
      {
        name: "broccoli",
        quantity: "1",
      },
      {
        name: "bell pepper",
        quantity: "1",
      },
      {
        name: "olive oil",
        quantity: "1",
      },
    ],
    source: "fondue pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/d0/Bacon_Fondue.png",
  },
  {
    name: "peanut noodles",
    level: 86,
    price: 597,
    time: 2280,
    xp: 80,
    needs: [
      {
        name: "ginger",
        quantity: "1",
      },
      {
        name: "rice noodles",
        quantity: "1",
      },
      {
        name: "soy sauce",
        quantity: "1",
      },
      {
        name: "peanuts",
        quantity: "1",
      },
    ],
    source: "wok kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/20/Peanut_Noodles.png",
  },
  {
    name: "gingerbread cookie",
    level: 86,
    price: 273,
    time: 1500,
    xp: 33,
    needs: [
      {
        name: "wheat",
        quantity: "5",
      },
      {
        name: "butter",
        quantity: "1",
      },
      {
        name: "syrup",
        quantity: "1",
      },
      {
        name: "ginger",
        quantity: "2",
      },
    ],
    source: "bakery",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/02/Gingerbread_Cookie.png",
  },
  {
    name: "cream donut",
    level: 86,
    price: 230,
    time: 1260,
    xp: 27,
    needs: [
      {
        name: "plain donut",
        quantity: "1",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "brown sugar",
        quantity: "1",
      },
    ],
    source: "donut maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/60/Cream_Donut.png",
  },
  {
    name: "nachos",
    level: 87,
    price: 432,
    time: 3780,
    xp: 52,
    needs: [
      {
        name: "cheese",
        quantity: "1",
      },
      {
        name: "corn",
        quantity: "4",
      },
      {
        name: "salsa",
        quantity: "1",
      },
    ],
    source: "taco kitchen",
    image: "https://static.wikia.nocookie.net/hayday/images/4/4c/Nachos.png",
  },
  {
    name: "potato omelet",
    level: 87,
    price: 270,
    time: 3780,
    xp: 32,
    needs: [
      {
        name: "egg",
        quantity: "3",
      },
      {
        name: "potato",
        quantity: "1",
      },
      {
        name: "bacon",
        quantity: "3",
      },
    ],
    source: "omelet station",
    image: "https://static.wikia.nocookie.net/hayday/images/c/ca/Potato_Omelet.png",
  },
  {
    name: "spicy pasta",
    level: 87,
    price: 576,
    time: 4560,
    xp: 69,
    needs: [
      {
        name: "fresh pasta",
        quantity: "3",
      },
      {
        name: "tomato sauce",
        quantity: "1",
      },
      {
        name: "chili pepper",
        quantity: "3",
      },
      {
        name: "onion",
        quantity: "2",
      },
    ],
    source: "pasta kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/e6/Spicy_Pasta.png",
  },
  {
    name: "broccoli soup",
    level: 87,
    price: 237,
    time: 3780,
    xp: 28,
    needs: [
      {
        name: "broccoli",
        quantity: "3",
      },
      {
        name: "onion",
        quantity: "1",
      },
      {
        name: "potato",
        quantity: "3",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b6/Broccoli_Soup.png",
  },
  {
    name: "bacon fries",
    level: 87,
    price: 302,
    time: 1260,
    xp: 36,
    needs: [
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "bacon",
        quantity: "2",
      },
      {
        name: "potato",
        quantity: "4",
      },
      {
        name: "garlic",
        quantity: "1",
      },
    ],
    source: "deep fryer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/80/Bacon_Fries.png",
  },
  {
    name: "mixed smoothie",
    level: 88,
    price: 504,
    time: 1500,
    xp: 60,
    needs: [
      {
        name: "lemon",
        quantity: "1",
      },
      {
        name: "orange",
        quantity: "2",
      },
      {
        name: "peach",
        quantity: "2",
      },
    ],
    source: "smoothie mixer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/6b/Mixed_Smoothie.png",
  },
  {
    name: "banana",
    level: 88,
    price: 104,
    time: 97200,
    xp: 20,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/6/69/Banana.png",
  },
  {
    name: "iced banana latte",
    level: 88,
    price: 277,
    time: 1020,
    xp: 33,
    needs: [
      {
        name: "banana",
        quantity: "1",
      },
      {
        name: "coffee bean",
        quantity: "2",
      },
      {
        name: "milk",
        quantity: "1",
      },
    ],
    source: "coffee kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b0/Iced_Banana_Latte.png",
  },
  {
    name: "apple ginger tea",
    level: 88,
    price: 169,
    time: 1500,
    xp: 20,
    needs: [
      {
        name: "ginger",
        quantity: "1",
      },
      {
        name: "apple",
        quantity: "2",
      },
      {
        name: "tea leaf",
        quantity: "1",
      },
    ],
    source: "tea stand",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/e3/Apple_Ginger_Tea.png",
  },
  {
    name: "bacon donut",
    level: 88,
    price: 388,
    time: 1500,
    xp: 46,
    needs: [
      {
        name: "plain donut",
        quantity: "1",
      },
      {
        name: "bacon",
        quantity: "3",
      },
      {
        name: "syrup",
        quantity: "1",
      },
    ],
    source: "donut maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/06/Bacon_Donut.png",
  },
  {
    name: "passion fruit",
    level: 88,
    price: 18,
    time: 3600,
    xp: 4,
    needs: [
      {
        name: "passion fruit",
        quantity: "1",
      },
    ],
    source: "field",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/fb/Passion_Fruit.png",
  },
  {
    name: "passion fruit juice",
    level: 88,
    price: 64,
    time: 2280,
    xp: 8,
    needs: [
      {
        name: "passion fruit",
        quantity: "2",
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/35/Passion_Fruit_Juice.png",
  },
  {
    name: "orange tea",
    level: 89,
    price: 255,
    time: 2040,
    xp: 30,
    needs: [
      {
        name: "orange",
        quantity: "1",
      },
      {
        name: "tea leaf",
        quantity: "3",
      },
    ],
    source: "tea stand",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/8a/Orange_Tea.png",
  },
  {
    name: "fruit cake",
    level: 89,
    price: 450,
    time: 9180,
    xp: 54,
    needs: [
      {
        name: "cherry",
        quantity: "2",
      },
      {
        name: "grapes",
        quantity: "2",
      },
      {
        name: "orange",
        quantity: "2",
      },
      {
        name: "wheat",
        quantity: "3",
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/47/Fruit_Cake.png",
  },
  {
    name: "mushroom",
    level: 89,
    price: 10,
    time: 1200,
    xp: 2,
    needs: [
      {
        name: "mushroom",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/8/8c/Mushroom.png",
  },
  {
    name: "mushroom salad",
    level: 89,
    price: 216,
    time: 3060,
    xp: 26,
    needs: [
      {
        name: "mushroom",
        quantity: "3",
      },
      {
        name: "lettuce",
        quantity: "1",
      },
      {
        name: "potato",
        quantity: "2",
      },
      {
        name: "bacon",
        quantity: "1",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/8f/Mushroom_Salad.png",
  },
  {
    name: "tofu stir fry",
    level: 89,
    price: 306,
    time: 3780,
    xp: 37,
    needs: [
      {
        name: "soyabean",
        quantity: "3",
      },
      {
        name: "onion",
        quantity: "3",
      },
      {
        name: "broccoli",
        quantity: "3",
      },
      {
        name: "sesame",
        quantity: "3",
      },
    ],
    source: "wok kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/db/Tofu_Stir_Fry.png",
  },
  {
    name: "candy bouquet",
    level: 90,
    price: 554,
    time: 1020,
    xp: 66,
    needs: [
      {
        name: "peony",
        quantity: "3",
      },
      {
        name: "toffee",
        quantity: "1",
      },
      {
        name: "caramel apple",
        quantity: "1",
      },
    ],
    source: "flower shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/1b/Candy_Bouquet.png",
  },
  {
    name: "eggplant",
    level: 90,
    price: 14,
    time: 2400,
    xp: 3,
    needs: [
      {
        name: "eggplant",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/8/8f/Eggplant.png",
  },
  {
    name: "grilled eggplant",
    level: 90,
    price: 324,
    time: 2040,
    xp: 39,
    needs: [
      {
        name: "eggplant",
        quantity: "3",
      },
      {
        name: "salsa",
        quantity: "1",
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/77/Grilled_Eggplant.png",
  },
  {
    name: "banana bread",
    level: 91,
    price: 424,
    time: 1500,
    xp: 50,
    needs: [
      {
        name: "banana",
        quantity: "3",
      },
      {
        name: "egg",
        quantity: "1",
      },
      {
        name: "grapes",
        quantity: "2",
      },
      {
        name: "wheat",
        quantity: "3",
      },
    ],
    source: "bakery",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/35/Banana_Bread.png",
  },
  {
    name: "cheese fondue",
    level: 91,
    price: 493,
    time: 1020,
    xp: 59,
    needs: [
      {
        name: "potato bread",
        quantity: "1",
      },
      {
        name: "cheese",
        quantity: "1",
      },
      {
        name: "mushroom",
        quantity: "2",
      },
      {
        name: "tomato",
        quantity: "1",
      },
    ],
    source: "fondue pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/3a/Cheese_Fondue.png",
  },
  {
    name: "hand pies",
    level: 91,
    price: 295,
    time: 1020,
    xp: 35,
    needs: [
      {
        name: "apple jam",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "wheat",
        quantity: "5",
      },
    ],
    source: "deep fryer",
    image: "https://static.wikia.nocookie.net/hayday/images/9/96/Hand_Pies.png",
  },
  {
    name: "pickles",
    level: 91,
    price: 270,
    time: 12240,
    xp: 32,
    needs: [
      {
        name: "cucumber",
        quantity: "3",
      },
      {
        name: "onion",
        quantity: "2",
      },
      {
        name: "lemon",
        quantity: "1",
      },
    ],
    source: "preservation station",
    image: "https://static.wikia.nocookie.net/hayday/images/c/c7/Pickles.png",
  },
  {
    name: "watermelon",
    level: 92,
    price: 39,
    time: 18000,
    xp: 8,
    needs: [
      {
        name: "watermelon",
        quantity: "1",
      },
    ],
    source: "field",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/06/Watermelon.png",
  },
  {
    name: "iced tea",
    level: 92,
    price: 252,
    time: 1500,
    xp: 30,
    needs: [
      {
        name: "peach",
        quantity: "1",
      },
      {
        name: "tea leaf",
        quantity: "3",
      },
    ],
    source: "tea stand",
    image: "https://static.wikia.nocookie.net/hayday/images/4/4f/Iced_Tea.png",
  },
  {
    name: "goat cheese toast",
    level: 92,
    price: 302,
    time: 2520,
    xp: 36,
    needs: [
      {
        name: "bread",
        quantity: "1",
      },
      {
        name: "goat cheese",
        quantity: "1",
      },
      {
        name: "grapes",
        quantity: "3",
      },
    ],
    source: "sandwich bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/87/Goat_Cheese_Toast.png",
  },
  {
    name: "watermelon juice",
    level: 92,
    price: 108,
    time: 3060,
    xp: 13,
    needs: [
      {
        name: "watermelon",
        quantity: "2",
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a3/Watermelon_Juice.png",
  },
  {
    name: "birthday bouquet",
    level: 92,
    price: 349,
    time: 1020,
    xp: 42,
    needs: [
      {
        name: "cherry juice",
        quantity: "1",
      },
      {
        name: "peony",
        quantity: "3",
      },
      {
        name: "lily",
        quantity: "1",
      },
      {
        name: "indigo",
        quantity: "1",
      },
    ],
    source: "flower shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/23/Birthday_Bouquet.png",
  },
  {
    name: "passion fruit pie",
    level: 92,
    price: 111,
    time: 2520,
    xp: 13,
    needs: [
      {
        name: "passion fruit",
        quantity: "3",
      },
      {
        name: "wheat",
        quantity: "2",
      },
      {
        name: "egg",
        quantity: "1",
      },
    ],
    source: "pie oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/65/Passion_Fruit_Pie.png",
  },
  {
    name: "soft bouquet",
    level: 93,
    price: 298,
    time: 1500,
    xp: 36,
    needs: [
      {
        name: "peony",
        quantity: "4",
      },
      {
        name: "cotton",
        quantity: "1",
      },
      {
        name: "cotton fabric",
        quantity: "1",
      },
    ],
    source: "flower shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/8a/Soft_Bouquet.png",
  },
  {
    name: "black sesame smoothie",
    level: 93,
    price: 313,
    time: 2280,
    xp: 37,
    needs: [
      {
        name: "sesame",
        quantity: "3",
      },
      {
        name: "banana",
        quantity: "1",
      },
      {
        name: "goat milk",
        quantity: "2",
      },
    ],
    source: "smoothie mixer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/3c/Black_Sesame_Smoothie.png",
  },
  {
    name: "exfoliating soap",
    level: 93,
    price: 363,
    time: 3060,
    xp: 43,
    needs: [
      {
        name: "cacao",
        quantity: "2",
      },
      {
        name: "coffee bean",
        quantity: "2",
      },
      {
        name: "raspberry",
        quantity: "1",
      },
    ],
    source: "bath kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/38/Exfoliating_Soap.png",
  },
  {
    name: "filled donut",
    level: 93,
    price: 403,
    time: 1740,
    xp: 48,
    needs: [
      {
        name: "plain donut",
        quantity: "1",
      },
      {
        name: "raspberry jam",
        quantity: "1",
      },
    ],
    source: "donut maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/30/Filled_Donut.png",
  },
  {
    name: "plum",
    level: 94,
    price: 82,
    time: 86400,
    xp: 16,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/e/e6/Plum.png",
  },
  {
    name: "banana pancakes",
    level: 94,
    price: 352,
    time: 3060,
    xp: 42,
    needs: [
      {
        name: "banana",
        quantity: "1",
      },
      {
        name: "blackberry",
        quantity: "2",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "wheat",
        quantity: "3",
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/4f/Banana_Pancakes.png",
  },
  {
    name: "plum jam",
    level: 94,
    price: 385,
    time: 15300,
    xp: 46,
    needs: [
      {
        name: "plum",
        quantity: "3",
      },
    ],
    source: "jam maker",
    image: "https://static.wikia.nocookie.net/hayday/images/3/3a/Plum_Jam.png",
  },
  {
    name: "clay",
    level: 94,
    price: 18,
    time: 6600,
    xp: 5,
    needs: [
      {
        name: "clay",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/a/a2/Clay.png",
  },
  {
    name: "tea pot",
    level: 94,
    price: 219,
    time: 11220,
    xp: 26,
    needs: [
      {
        name: "clay",
        quantity: "3",
      },
      {
        name: "coffee bean",
        quantity: "1",
      },
      {
        name: "gold ore",
        quantity: "1",
      },
    ],
    source: "pottery studio",
    image: "https://static.wikia.nocookie.net/hayday/images/6/6e/Tea_Pot.png",
  },
  {
    name: "floral candle",
    level: 95,
    price: 442,
    time: 6120,
    xp: 53,
    needs: [
      {
        name: "beeswax",
        quantity: "1",
      },
      {
        name: "peony",
        quantity: "2",
      },
      {
        name: "sunflower",
        quantity: "2",
      },
      {
        name: "indigo",
        quantity: "3",
      },
    ],
    source: "candle maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c8/Floral_Candle.png",
  },
  {
    name: "chocolate roll",
    level: 95,
    price: 604,
    time: 4560,
    xp: 72,
    needs: [
      {
        name: "cacao",
        quantity: "5",
      },
      {
        name: "cream",
        quantity: "1",
      },
      {
        name: "wheat",
        quantity: "3",
      },
      {
        name: "mint",
        quantity: "3",
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a3/Chocolate_Roll.png",
  },
  {
    name: "canned fish",
    level: 95,
    price: 471,
    time: 11220,
    xp: 56,
    needs: [
      {
        name: "fish fillet",
        quantity: "2",
      },
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "olive oil",
        quantity: "1",
      },
    ],
    source: "preservation station",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/81/Canned_Fish.png",
  },
  {
    name: "chickpea",
    level: 95,
    price: 18,
    time: 3600,
    xp: 4,
    needs: [
      {
        name: "chickpea",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/0/02/Chickpea.png",
  },
  {
    name: "hummus",
    level: 95,
    price: 277,
    time: 1500,
    xp: 33,
    needs: [
      {
        name: "chickpea",
        quantity: "3",
      },
      {
        name: "lemon",
        quantity: "2",
      },
      {
        name: "garlic",
        quantity: "1",
      },
    ],
    source: "sauce maker",
    image: "https://static.wikia.nocookie.net/hayday/images/7/78/Hummus.png",
  },
  {
    name: "banana split",
    level: 96,
    price: 403,
    time: 10680,
    xp: 48,
    needs: [
      {
        name: "banana",
        quantity: "1",
      },
      {
        name: "cherry",
        quantity: "3",
      },
      {
        name: "cream",
        quantity: "1",
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/bd/Banana_Split.png",
  },
  {
    name: "fish skewer",
    level: 96,
    price: 176,
    time: 1500,
    xp: 21,
    needs: [
      {
        name: "fish fillet",
        quantity: "1",
      },
      {
        name: "mushroom",
        quantity: "3",
      },
      {
        name: "ginger",
        quantity: "1",
      },
      {
        name: "sesame",
        quantity: "2",
      },
    ],
    source: "BBQ grill",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/80/Fish_Skewer.png",
  },
  {
    name: "passion fruit jam",
    level: 96,
    price: 118,
    time: 16320,
    xp: 14,
    needs: [
      {
        name: "passion fruit",
        quantity: "3",
      },
    ],
    source: "jam maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/d7/Passion_Fruit_Jam.png",
  },
  {
    name: "potted plant",
    level: 96,
    price: 151,
    time: 11220,
    xp: 26,
    needs: [
      {
        name: "clay",
        quantity: "3",
      },
      {
        name: "sunflower",
        quantity: "1",
      },
    ],
    source: "pottery studio",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a1/Potted_Plant.png",
  },
  {
    name: "mint tea",
    level: 97,
    price: 255,
    time: 1740,
    xp: 31,
    needs: [
      {
        name: "tea leaf",
        quantity: "3",
      },
      {
        name: "mint",
        quantity: "1",
      },
      {
        name: "watermelon",
        quantity: "1",
      },
    ],
    source: "tea stand",
    image: "https://static.wikia.nocookie.net/hayday/images/4/40/Mint_Tea.png",
  },
  {
    name: "mushroom pot pie",
    level: 97,
    price: 162,
    time: 3060,
    xp: 19,
    needs: [
      {
        name: "mushroom",
        quantity: "3",
      },
      {
        name: "cabbage",
        quantity: "3",
      },
      {
        name: "wheat",
        quantity: "3",
      },
      {
        name: "egg",
        quantity: "2",
      },
    ],
    source: "pie oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c2/Mushroom_Pot_Pie.png",
  },
  {
    name: "mango",
    level: 97,
    price: 100,
    time: 115200,
    xp: 20,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/3/38/Mango.png",
  },
  {
    name: "mango juice",
    level: 97,
    price: 230,
    time: 2520,
    xp: 27,
    needs: [
      {
        name: "mango",
        quantity: "2",
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/e1/Mango_Juice.png",
  },
  {
    name: "flower pendant",
    level: 98,
    price: 698,
    time: 3060,
    xp: 83,
    needs: [
      {
        name: "peony",
        quantity: "3",
      },
      {
        name: "platinum bar",
        quantity: "1",
      },
      {
        name: "gold bar",
        quantity: "2",
      },
    ],
    source: "jeweler",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/ae/Flower_Pendant.png",
  },
  {
    name: "chili poppers",
    level: 98,
    price: 406,
    time: 2040,
    xp: 48,
    needs: [
      {
        name: "chili pepper",
        quantity: "3",
      },
      {
        name: "wheat",
        quantity: "1",
      },
      {
        name: "bacon",
        quantity: "3",
      },
      {
        name: "cheese",
        quantity: "1",
      },
    ],
    source: "deep fryer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/8f/Chili_Poppers.png",
  },
  {
    name: "falafel",
    level: 98,
    price: 226,
    time: 2760,
    xp: 27,
    needs: [
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "chickpea",
        quantity: "3",
      },
      {
        name: "onion",
        quantity: "2",
      },
      {
        name: "garlic",
        quantity: "2",
      },
    ],
    source: "deep fryer",
    image: "https://static.wikia.nocookie.net/hayday/images/5/5c/Falafel.png",
  },
  {
    name: "kimchi",
    level: 98,
    price: 219,
    time: 15300,
    xp: 26,
    needs: [
      {
        name: "cabbage",
        quantity: "5",
      },
      {
        name: "ginger",
        quantity: "1",
      },
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "garlic",
        quantity: "1",
      },
    ],
    source: "preservation station",
    image: "https://static.wikia.nocookie.net/hayday/images/4/43/Kimchi.png",
  },
  {
    name: "eggplant parmesan",
    level: 99,
    price: 442,
    time: 2280,
    xp: 53,
    needs: [
      {
        name: "eggplant",
        quantity: "3",
      },
      {
        name: "cheese",
        quantity: "1",
      },
      {
        name: "tomato sauce",
        quantity: "1",
      },
    ],
    source: "pie oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/73/Eggplant_Parmesan.png",
  },
  {
    name: "honey face mask",
    level: 99,
    price: 320,
    time: 4560,
    xp: 38,
    needs: [
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "lemon",
        quantity: "1",
      },
    ],
    source: "bath kiosk",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/f1/Honey_Face_Mask.png",
  },
  {
    name: "clay mug",
    level: 99,
    price: 212,
    time: 10200,
    xp: 26,
    needs: [
      {
        name: "clay",
        quantity: "3",
      },
      {
        name: "indigo",
        quantity: "2",
      },
      {
        name: "peony",
        quantity: "1",
      },
    ],
    source: "pottery studio",
    image: "https://static.wikia.nocookie.net/hayday/images/f/f1/Clay_Mug.png",
  },
  {
    name: "rich fudge",
    level: 99,
    price: 644,
    time: 6120,
    xp: 77,
    needs: [
      {
        name: "cacao",
        quantity: "3",
      },
      {
        name: "butter",
        quantity: "3",
      },
      {
        name: "white sugar",
        quantity: "2",
      },
    ],
    source: "fudge shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/2e/Rich_Fudge.png",
  },
  {
    name: "cocoa smoothie",
    level: 100,
    price: 511,
    time: 2040,
    xp: 61,
    needs: [
      {
        name: "banana",
        quantity: "2",
      },
      {
        name: "cacao",
        quantity: "3",
      },
      {
        name: "milk",
        quantity: "1",
      },
    ],
    source: "smoothie mixer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c4/Cocoa_Smoothie.png",
  },
  {
    name: "fried candy bar",
    level: 100,
    price: 658,
    time: 720,
    xp: 87,
    needs: [
      {
        name: "cacao",
        quantity: "3",
      },
      {
        name: "cookie",
        quantity: "1",
      },
      {
        name: "peanuts",
        quantity: "1",
      },
    ],
    source: "deep fryer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/96/Fried_Candy_Bar.png",
  },
  {
    name: "tart dressing",
    level: 100,
    price: 288,
    time: 1500,
    xp: 34,
    needs: [
      {
        name: "passion fruit",
        quantity: "2",
      },
      {
        name: "chili pepper",
        quantity: "2",
      },
      {
        name: "honey",
        quantity: "1",
      },
    ],
    source: "sauce maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/98/Tart_Dressing.png",
  },
  {
    name: "tropical fondue",
    level: 100,
    price: 417,
    time: 1740,
    xp: 50,
    needs: [
      {
        name: "white sugar",
        quantity: "1",
      },
      {
        name: "cream",
        quantity: "2",
      },
      {
        name: "pineapple",
        quantity: "3",
      },
      {
        name: "mango",
        quantity: "2",
      },
    ],
    source: "fondue pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/f8/Tropical_Fondue.png",
  },
  {
    name: "mushroom pasta",
    level: 101,
    price: 280,
    time: 3780,
    xp: 33,
    needs: [
      {
        name: "mushroom",
        quantity: "5",
      },
      {
        name: "fresh pasta",
        quantity: "3",
      },
      {
        name: "goat milk",
        quantity: "1",
      },
    ],
    source: "pasta kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/2d/Mushroom_Pasta.png",
  },
  {
    name: "coconut",
    level: 101,
    price: 108,
    time: 129600,
    xp: 21,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/2/2f/Coconut.png",
  },
  {
    name: "macaroon",
    level: 101,
    price: 421,
    time: 1500,
    xp: 50,
    needs: [
      {
        name: "cacao",
        quantity: "1",
      },
      {
        name: "coconut",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "2",
      },
    ],
    source: "bakery",
    image: "https://static.wikia.nocookie.net/hayday/images/d/d0/Macaroon.png",
  },
  {
    name: "plum smoothie",
    level: 102,
    price: 522,
    time: 1740,
    xp: 62,
    needs: [
      {
        name: "plum",
        quantity: "3",
      },
      {
        name: "grapes",
        quantity: "1",
      },
      {
        name: "mint",
        quantity: "1",
      },
      {
        name: "honey",
        quantity: "1",
      },
    ],
    source: "smoothie mixer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c5/Plum_Smoothie.png",
  },
  {
    name: "dried fruit",
    level: 102,
    price: 266,
    time: 9180,
    xp: 32,
    needs: [
      {
        name: "grapes",
        quantity: "1",
      },
      {
        name: "raspberry",
        quantity: "1",
      },
      {
        name: "strawberry",
        quantity: "1",
      },
      {
        name: "orange",
        quantity: "1",
      },
    ],
    source: "preservation station",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/66/Dried_Fruit.png",
  },
  {
    name: "mint fudge",
    level: 102,
    price: 522,
    time: 7620,
    xp: 62,
    needs: [
      {
        name: "butter",
        quantity: "2",
      },
      {
        name: "cacao",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
      {
        name: "mint",
        quantity: "3",
      },
    ],
    source: "fudge shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/40/Mint_Fudge.png",
  },
  {
    name: "coconut ice cream",
    level: 102,
    price: 320,
    time: 720,
    xp: 38,
    needs: [
      {
        name: "coconut",
        quantity: "2",
      },
      {
        name: "syrup",
        quantity: "1",
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/d9/Coconut_Ice_Cream.png",
  },
  {
    name: "samosa",
    level: 103,
    price: 223,
    time: 3780,
    xp: 27,
    needs: [
      {
        name: "chickpea",
        quantity: "2",
      },
      {
        name: "wheat",
        quantity: "4",
      },
      {
        name: "chili pepper",
        quantity: "1",
      },
      {
        name: "potato",
        quantity: "3",
      },
    ],
    source: "deep fryer",
    image: "https://static.wikia.nocookie.net/hayday/images/b/b5/Samosa.png",
  },
  {
    name: "plain yogurt",
    level: 103,
    price: 234,
    time: 6120,
    xp: 28,
    needs: [
      {
        name: "milk",
        quantity: "3",
      },
      {
        name: "cream",
        quantity: "2",
      },
    ],
    source: "yogurt maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/6a/Plain_Yogurt.png",
  },
  {
    name: "mushroom soup",
    level: 104,
    price: 176,
    time: 4080,
    xp: 21,
    needs: [
      {
        name: "mushroom",
        quantity: "3",
      },
      {
        name: "milk",
        quantity: "1",
      },
      {
        name: "onion",
        quantity: "2",
      },
    ],
    source: "soup kitchen",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/d7/Mushroom_Soup.png",
  },
  {
    name: "chili fudge",
    level: 104,
    price: 540,
    time: 8700,
    xp: 64,
    needs: [
      {
        name: "butter",
        quantity: "2",
      },
      {
        name: "cacao",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
      {
        name: "chili pepper",
        quantity: "3",
      },
    ],
    source: "fudge shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a2/Chili_Fudge.png",
  },
  {
    name: "tropical smoothie",
    level: 104,
    price: 475,
    time: 2040,
    xp: 57,
    needs: [
      {
        name: "coconut",
        quantity: "1",
      },
      {
        name: "banana",
        quantity: "1",
      },
      {
        name: "passion fruit",
        quantity: "2",
      },
      {
        name: "mango",
        quantity: "2",
      },
    ],
    source: "smoothie mixer",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/3e/Tropical_Smoothie.png",
  },
  {
    name: "guava",
    level: 104,
    price: 111,
    time: 122400,
    xp: 22,
    needs: [],
    source: "tree",
    image: "https://static.wikia.nocookie.net/hayday/images/8/87/Guava.png",
  },
  {
    name: "guava juice",
    level: 104,
    price: 252,
    time: 2760,
    xp: 30,
    needs: [
      {
        name: "guava",
        quantity: "2",
      },
    ],
    source: "juice press",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c7/Guava_Juice.png",
  },
  {
    name: "guava compote",
    level: 104,
    price: 421,
    time: 13260,
    xp: 30,
    needs: [
      {
        name: "guava",
        quantity: "2",
      },
      {
        name: "lemon",
        quantity: "1",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
    ],
    source: "preservation station",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/4e/Guava_Compote.png",
  },
  {
    name: "strawberry yogurt",
    level: 105,
    price: 529,
    time: 2040,
    xp: 63,
    needs: [
      {
        name: "plain yogurt",
        quantity: "1",
      },
      {
        name: "strawberry jam",
        quantity: "1",
      },
    ],
    source: "yogurt maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/52/Strawberry_Yogurt.png",
  },
  {
    name: "veggie bouquet",
    level: 106,
    price: 352,
    time: 720,
    xp: 42,
    needs: [
      {
        name: "broccoli",
        quantity: "3",
      },
      {
        name: "tomato",
        quantity: "3",
      },
      {
        name: "mushroom",
        quantity: "3",
      },
      {
        name: "cotton fabric",
        quantity: "1",
      },
    ],
    source: "flower shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/f9/Veggie_Bouquet.png",
  },
  {
    name: "chickpea stew",
    level: 106,
    price: 284,
    time: 4560,
    xp: 34,
    needs: [
      {
        name: "coconut",
        quantity: "1",
      },
      {
        name: "tomato",
        quantity: "2",
      },
      {
        name: "chickpea",
        quantity: "3",
      },
    ],
    source: "stew pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/a/a5/Chickpea_Stew.png",
  },
  {
    name: "fruit sorbet",
    level: 106,
    price: 518,
    time: 3060,
    xp: 62,
    needs: [
      {
        name: "guava",
        quantity: "1",
      },
      {
        name: "passion fruit",
        quantity: "1",
      },
      {
        name: "mango",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "3",
      },
    ],
    source: "ice cream maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/b9/Fruit_Sorbet.png",
  },
  {
    name: "lemon fudge",
    level: 108,
    price: 590,
    time: 5580,
    xp: 70,
    needs: [
      {
        name: "butter",
        quantity: "2",
      },
      {
        name: "lemon",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "2",
      },
      {
        name: "milk",
        quantity: "3",
      },
    ],
    source: "fudge shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/d2/Lemon_Fudge.png",
  },
  {
    name: "hummus wrap",
    level: 109,
    price: 374,
    time: 1500,
    xp: 45,
    needs: [
      {
        name: "bread",
        quantity: "2",
      },
      {
        name: "lettuce",
        quantity: "1",
      },
      {
        name: "hummus",
        quantity: "1",
      },
    ],
    source: "sandwich bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/3/31/Hummus_Wrap.png",
  },
  {
    name: "tropical yogurt",
    level: 109,
    price: 457,
    time: 3060,
    xp: 54,
    needs: [
      {
        name: "plain yogurt",
        quantity: "1",
      },
      {
        name: "raspberry",
        quantity: "4",
      },
      {
        name: "passion fruit",
        quantity: "1",
      },
      {
        name: "mango",
        quantity: "2",
      },
    ],
    source: "yogurt maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/cc/Tropical_Yogurt.png",
  },
  {
    name: "chili stew",
    level: 109,
    price: 370,
    time: 6120,
    xp: 44,
    needs: [
      {
        name: "chili pepper",
        quantity: "3",
      },
      {
        name: "bacon",
        quantity: "2",
      },
      {
        name: "onion",
        quantity: "2",
      },
      {
        name: "cream",
        quantity: "1",
      },
    ],
    source: "stew pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/c6/Chili_Stew.png",
  },
  {
    name: "plain cupcake",
    level: 109,
    price: 280,
    time: 2040,
    xp: 34,
    needs: [
      {
        name: "milk",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "3",
      },
      {
        name: "egg",
        quantity: "1",
      },
      {
        name: "wheat",
        quantity: "5",
      },
    ],
    source: "cupcake maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/c/ce/Plain_Cupcake.png",
  },
  {
    name: "guava cupcake",
    level: 109,
    price: 583,
    time: 3540,
    xp: 70,
    needs: [
      {
        name: "plain cupcake",
        quantity: "1",
      },
      {
        name: "guava",
        quantity: "2",
      },
      {
        name: "cream",
        quantity: "1",
      },
    ],
    source: "cupcake maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/9e/Guava_Cupcake.png",
  },
  {
    name: "rice ball",
    level: 110,
    price: 464,
    time: 2280,
    xp: 55,
    needs: [
      {
        name: "rice",
        quantity: "20",
      },
      {
        name: "sesame",
        quantity: "1",
      },
      {
        name: "plum",
        quantity: "1",
      },
    ],
    source: "sushi bar",
    image: "https://static.wikia.nocookie.net/hayday/images/1/1d/Rice_Ball.png",
  },
  {
    name: "peanut fudge",
    level: 111,
    price: 1141,
    time: 4560,
    xp: 136,
    needs: [
      {
        name: "butter",
        quantity: "2",
      },
      {
        name: "cacao",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "1",
      },
      {
        name: "peanuts",
        quantity: "2",
      },
    ],
    source: "fudge shop",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/2d/Peanut_Fudge.png",
  },
  {
    name: "tropical cupcake",
    level: 112,
    price: 572,
    time: 4560,
    xp: 68,
    needs: [
      {
        name: "plain cupcake",
        quantity: "1",
      },
      {
        name: "pineapple",
        quantity: "3",
      },
      {
        name: "coconut",
        quantity: "2",
      },
    ],
    source: "cupcake maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/9b/Tropical_Cupcake.png",
  },
  {
    name: "winter stew",
    level: 112,
    price: 295,
    time: 7140,
    xp: 35,
    needs: [
      {
        name: "bacon",
        quantity: "1",
      },
      {
        name: "potato",
        quantity: "3",
      },
      {
        name: "onion",
        quantity: "2",
      },
      {
        name: "mushroom",
        quantity: "2",
      },
    ],
    source: "stew pot",
    image:
      "https://static.wikia.nocookie.net/hayday/images/e/e3/Winter_Stew.png",
  },
  {
    name: "cookie cupcake",
    level: 114,
    price: 712,
    time: 6120,
    xp: 85,
    needs: [
      {
        name: "plain cupcake",
        quantity: "1",
      },
      {
        name: "cream",
        quantity: "2",
      },
      {
        name: "cookie",
        quantity: "2",
      },
      {
        name: "cacao",
        quantity: "1",
      },
    ],
    source: "cupcake maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/8/86/Cookie_Cupcake.png",
  },
  {
    name: "pomegranate",
    level: 107,
    price: 111,
    time: 97200,
    xp: 45,
    needs: [],
    source: "tree",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/1b/Pomegranate.png",
  },
  {
    name: "pomegranate tea",
    level: 107,
    price: 313,
    time: 2040,
    xp: 37,
    needs: [
      {
        name: "tea leaf",
        quantity: "4",
      },
      {
        name: "pomegranate",
        quantity: "1",
      },
    ],
    source: "tea stand",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/f1/Pomegranate_Tea.png",
  },
  {
    name: "pomegranate cake",
    level: 108,
    price: 316,
    time: 9600,
    xp: 38,
    needs: [
      {
        name: "pomegranate",
        quantity: "1",
      },
      {
        name: "egg",
        quantity: "2",
      },
      {
        name: "cream",
        quantity: "2",
      },
      {
        name: "wheat",
        quantity: "6",
      },
    ],
    source: "cake oven",
    image:
      "https://static.wikia.nocookie.net/hayday/images/9/9d/Pomegranate_Cake.png",
  },
  {
    name: "berry waffle",
    level: 114,
    price: 604,
    time: 1740,
    xp: 24,
    needs: [
      {
        name: "plain waffle",
        quantity: "1",
      },
      {
        name: "syrup",
        quantity: "1",
      },
      {
        name: "raspberry",
        quantity: "3",
      },
      {
        name: "blackberry",
        quantity: "2",
      },
    ],
    source: "waffle maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/29/Berry_Waffles.png",
  },
  {
    name: "plain waffle",
    level: 114,
    price: 198,
    time: 1260,
    xp: 24,
    needs: [
      {
        name: "white sugar",
        quantity: "2",
      },
      {
        name: "egg",
        quantity: "3",
      },
      {
        name: "wheat",
        quantity: "5",
      },
    ],
    source: "waffle maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/66/Plain_Waffles.png",
  },
  {
    name: "chocolate waffle",
    level: 117,
    price: 637,
    time: 2040,
    xp: 76,
    needs: [
      {
        name: "plain waffle",
        quantity: "1",
      },
      {
        name: "cacao",
        quantity: "2",
      },
      {
        name: "strawberry",
        quantity: "3",
      },
      {
        name: "banana",
        quantity: "1",
      },
    ],
    source: "waffle maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/4/4d/Chocolate_Waffles.png",
  },
  {
    name: "orange salad",
    level: 117,
    price: 558,
    time: 2280,
    xp: 66,
    needs: [
      {
        name: "cheese",
        quantity: "2",
      },
      {
        name: "cabbage",
        quantity: "3",
      },
      {
        name: "pomegranate",
        quantity: "1",
      },
      {
        name: "orange",
        quantity: "1",
      },
    ],
    source: "salad bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/b/bb/Orange_Salad.png",
  },
  {
    name: "breakfast waffle",
    level: 119,
    price: 424,
    time: 2280,
    xp: 51,
    needs: [
      {
        name: "plain waffle",
        quantity: "1",
      },
      {
        name: "bacon",
        quantity: "2",
      },
      {
        name: "egg",
        quantity: "1",
      },
      {
        name: "asparagus",
        quantity: "2",
      },
    ],
    source: "waffle maker",
    image:
      "https://static.wikia.nocookie.net/hayday/images/5/59/Breakfast_Waffles.png",
  },
  {
    name: "oats",
    level: 119,
    price: 7,
    time: 630,
    xp: 1,
    needs: [
      {
        name: "oats",
        quantity: "1",
      },
    ],
    source: "field",
    image: "https://static.wikia.nocookie.net/hayday/images/3/34/Oats.png",
  },
  {
    name: "apple porridge",
    level: 119,
    price: 522,
    time: 1020,
    xp: 62,
    needs: [
      {
        name: "apple",
        quantity: "1",
      },
      {
        name: "apple jam",
        quantity: "2",
      },
      {
        name: "oats",
        quantity: "4",
      },
    ],
    source: "porridge bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/d/d3/Apple_Porridge.png",
  },
  {
    name: "breakfast bowl",
    level: 119,
    price: 604,
    time: 2520,
    xp: 72,
    needs: [
      {
        name: "plain yogurt",
        quantity: "1",
      },
      {
        name: "honey",
        quantity: "1",
      },
      {
        name: "oats",
        quantity: "4",
      },
      {
        name: "blackberry",
        quantity: "2",
      },
    ],
    source: "porridge bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/2/2e/Breakfast_Bowl.png",
  },
  {
    name: "sweet porridge",
    level: 120,
    price: 466,
    time: 2280,
    xp: 55,
    needs: [
      {
        name: "peach",
        quantity: "1",
      },
      {
        name: "honey",
        quantity: "2",
      },
      {
        name: "oats",
        quantity: "3",
      },
    ],
    source: "porridge bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/fb/Sweet_Porridge.png",
  },
  {
    name: "pineapple coconut bars",
    level: 120,
    price: 284,
    time: 2040,
    xp: 34,
    needs: [
      {
        name: "pineapple",
        quantity: "2",
      },
      {
        name: "white sugar",
        quantity: "2",
      },
      {
        name: "oats",
        quantity: "3",
      },
      {
        name: "coconut",
        quantity: "1",
      },
    ],
    source: "bakery",
    image:
      "https://static.wikia.nocookie.net/hayday/images/6/61/Pineapple_Coconut_Bars.png",
  },
  {
    name: "rich soap",
    level: 121,
    price: 266,
    time: 3540,
    xp: 32,
    needs: [
      {
        name: "pomegranate",
        quantity: "1",
      },
      {
        name: "coconut",
        quantity: "1",
      },
      {
        name: "oats",
        quantity: "2",
      },
    ],
    source: "bath kisok",
    image: "https://static.wikia.nocookie.net/hayday/images/2/2e/Rich_Soap.png",
  },
  {
    name: "fresh porridge",
    level: 122,
    price: 435,
    time: 1740,
    xp: 52,
    needs: [
      {
        name: "pomegranate",
        quantity: "1",
      },
      {
        name: "mint",
        quantity: "2",
      },
      {
        name: "coconut",
        quantity: "2",
      },
      {
        name: "oats",
        quantity: "3",
      },
    ],
    source: "porridge bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/0/09/Fresh_Porridge.png",
  },
  {
    name: "vanilla milkshake",
    level: 124,
    price: 673,
    time: 2280,
    xp: 80,
    needs: [
      {
        name: "vanilla ice cream",
        quantity: "1",
      },
      {
        name: "cookie",
        quantity: "2",
      },
      {
        name: "toffee",
        quantity: "1",
      },
      {
        name: "syrup",
        quantity: "1",
      },
    ],
    source: "milkshake bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/1/14/Vanilla_Milkshake.png",
  },
  {
    name: "mocha milkshake",
    level: 125,
    price: 856,
    time: 1500,
    xp: 102,
    needs: [
      {
        name: "chocolate ice cream",
        quantity: "1",
      },
      {
        name: "espresso",
        quantity: "2",
      }
    ],
    source: "milkshake bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/7/7d/Mocha_Milkshake.png",
  },
  {
    name: "fruity milkshake",
    level: 126,
    price: 759,
    time: 1740,
    xp: 90,
    needs: [
      {
        name: "peach ice cream",
        quantity: "1",
      },
      {
        name: "orange",
        quantity: "2",
      },
      {
        name: "lemon",
        quantity: "1",
      }
    ],
    source: "milkshake bar",
    image:
      "https://static.wikia.nocookie.net/hayday/images/f/fb/Fruity_Milkshake.png",
  }
];
