import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  canvas: {
    display: "block",
    margin: "auto",
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: "black",
    padding: "5px",
    borderRadius: "5px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)"
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  buttonText: {
    marginLeft: '0.5rem',
  },
}));

export default function Collage(props) {
  const classes = useStyles();
  const canvasRef = useRef(null);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const columns = props.columns;
    const rows = Math.ceil(props.items.length / props.columns);
    canvas.width = columns * 100;
    canvas.height = rows * 100 + 20; // Add 20px to the height for the text at the bottom

    ctx.fillStyle = "black";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

    const imagePromises = props.items.map((item) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = item.images;
        img.onload = () => resolve({ img, quantity: item.quantity });
      });
    });

    Promise.all(imagePromises).then((imagesWithQuantity) => {
      let x = 0;
      let y = 0;

      imagesWithQuantity.forEach(({ img, quantity }, index) => {
        ctx.drawImage(img, x, y, 100, 100);

        ctx.font = "bold 16px Arial";
        ctx.fillStyle = "white";
        ctx.shadowColor = "#000";
        ctx.shadowOffsetX = 1;
        ctx.shadowOffsetY = 1;
        ctx.shadowBlur = 0;
        const textWidth = ctx.measureText(quantity).width;
        ctx.fillText(quantity, x + 100 - textWidth - 5, y + 100 - 5);

        x += 100;

        if ((index + 1) % props.columns === 0) {
          x = 0;
          y += 100;
        }
      });

      // Add the text at the bottom of the canvas
      const text = "Made with www.haydaycalculator.live";
      ctx.font = "bold 12px Arial";
      ctx.fillStyle = "white";
      ctx.shadowColor = "#000";
      ctx.shadowOffsetX = 1;
      ctx.shadowOffsetY = 1;
      ctx.shadowBlur = 0;
      const textWidth = ctx.measureText(text).width;

      if (textWidth < canvas.width - 20) {
        ctx.fillText(text, canvas.width - textWidth - 10, canvas.height - 10);
      } else {
        const words = text.split(" ");
        const firstLine = words.slice(0, 2).join(" ");
        const secondLine = words.slice(2).join(" ");
        const firstLineWidth = ctx.measureText(firstLine).width;
        const secondLineWidth = ctx.measureText(secondLine).width;
        ctx.fillText(firstLine, canvas.width - firstLineWidth - 10, canvas.height - 22);
        ctx.fillText(secondLine, canvas.width - secondLineWidth - 10, canvas.height - 10);
    }

    setIsRendered(true);
  });
}, [props.items, props.columns]);

const handleDownload = () => {
  const link = document.createElement("a");
  link.download = "collage.png";
  link.href = canvasRef.current.toDataURL("image/png");
  link.click();
};

const handleCopyToClipboard = async () => {
    try {
      const blob = await canvasToBlob(canvasRef.current);
      const item = new ClipboardItem({ "image/png": blob });
      await navigator.clipboard.write([item]);
      alert("Image copied to clipboard!");
    } catch (error) {
      alert("Failed to copy image to clipboard.");
    }
  };
  

  const canvasToBlob = (canvas, mimeType = "image/png") => {
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, mimeType);
    });
  };
  

  return (
    <div>
      <button className={classes.button} onClick={handleDownload}>
        <IconButton>
          <SaveIcon />
        </IconButton>
        <span className={classes.buttonText}>Download Image</span>
      </button>
      <button className={classes.button} onClick={handleCopyToClipboard}>
        <IconButton>
          <FileCopyIcon />
        </IconButton>
        <span className={classes.buttonText}>Copy to Clipboard</span>
      </button>
      <canvas ref={canvasRef} className={classes.canvas} sx={{ marginBottom: '200px' }} />

    </div>
  );
}

