import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { getGood, getGoods } from "../../api/fetch";
import SearchBar from "material-ui-search-bar";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import  LazyLoadImage  from 'react-lazy-load';
import LazyLoad from 'react-lazy-load';
import Collage from '../../components/imageMaker/collage'
import Container from '@mui/material/Container';
import { Constants } from "../../config/constants";
import { goods } from '../../goods'
const textFieldStyle = {
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '5px',
  color: '',
};

function strtime(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  if (hours === 0 && minutes === 0) return seconds + " sec";
  if (hours === 0 && seconds === 0) return minutes + " min";
  if (seconds === 0 && minutes === 0) return hours + " hr";
  if (hours === 0) return minutes + " min" + seconds + " sec";
  if (seconds === 0) return hours + " hr " + minutes + " min";
  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    const aWithoutNeeds = { ...a[0], needs: null };
    const bWithoutNeeds = { ...b[0], needs: null };
    return aWithoutNeeds.needs === null
      ? -1
      : bWithoutNeeds.needs === null
      ? 1
      : comparator(aWithoutNeeds, bWithoutNeeds);
    //return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "image",
    disableSorting: true
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "level",
    numeric: true,
    disablePadding: false,
    label: "Level",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "price",
  },
  {
    id: "source",
    numeric: false,
    disablePadding: false,
    label: "source",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "quantity",
    disableSorting: true
  },
];

function EnhancedTableHead(props) {
  const {
    //onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSorting ? (
              headCell.label 
            ) : (
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
    )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const [rows, setData] = useState([]);
  const [orgRows, setOrgRows] = useState([]);
  const [input, setInput] = useState({});
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("level");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(400);
  const [searched, setSearched] = React.useState("");
  const [quantity, setQuantity] = useState({});
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [calculateText, setCalculateText] = React.useState("");
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [multiplier, setMultiplier] = useState(5);
  const [newList, setNewList] = useState('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [numColumns, setNumColumns] = useState(5);
  // const [textareaValue, setTextareaValue] = useState(calculateText);

  // const handlePopulateNumbers = () => {
  //   const textarea = document.getElementById("filled-multiline-static");
  //   const numbers = textarea.value.match(/\d+/g); // Extract numbers from the textarea text
  //   const updatedQuantity = {};

  //   // Populate the numbers in the inputs
  //   for (const key in quantity) {
  //     const price = quantity[key][1];
  //     updatedQuantity[key] = [numbers[key] || "0", price];
  //   }

  //   setQuantity(updatedQuantity);
  // };

  // const handleTextareaChange = (event) => {
  //   setTextareaValue(event.target.value);
  // };
  
  const handleInputChange = (event, productId, price) => {
    if(event.target.value==""){
      setQuantity({ ...quantity, [productId]: ["0", price] });
    }else{
      setQuantity({ ...quantity, [productId]: [event.target.value, price] });
    }
  };

  useEffect(() => {
    let sum = 0;
    let text = `Your List is- \n`;
    let totalFoods = 0;
    for (const key in quantity) {
      sum += parseInt(quantity[key][0]) * parseInt(quantity[key][1]);
      if (parseInt(quantity[key][0]) != 0) {
        text += `\u2022  ${quantity[key][0]} - ${key} = ${parseInt(quantity[key][0]) * parseInt(quantity[key][1])}\n`;
      }
      totalFoods += parseInt(quantity[key][0]);
    }

    text += `
Total Foods = ${totalFoods}
Total Price = ${sum}
Total Price * ${multiplier} = ${sum * multiplier}
Total Blankets Required = ${Math.round((((sum * multiplier) / 1098))*100) / 100}
Total Rings Required = ${Math.round((((sum * multiplier) / 824))*100) / 100} `;

    setTotalPrice(sum * multiplier);
    setCalculateText(text);
    //localStorage.setItem("userPriceCalcList", JSON.stringify(quantity));
  }, [quantity, multiplier]);

  useEffect(() => {
    let data = null;
    if(Constants.takeDataFromFirebase){
      data = localStorage.getItem(Constants.cached_goods);
      data = JSON.parse(data);
    } else {
      data = goods
    }
    let cachedUserData = localStorage.getItem("userPriceCalcList")
    if(cachedUserData) {
      setQuantity(JSON.parse(cachedUserData));
    }
    if (data) {
      setData(data);
      setOrgRows(data);
    }else{
      const timeoutId = setTimeout(() => {
        window.location.reload();
      }, 3000); 
      return () => clearTimeout(timeoutId);
    }
  }, []);

  const getData = () => {
    const data =  getGoods();
    console.log("Firebase API called on price calculator")
    Promise.resolve(data).then((res) => {
      setData(res);
      setOrgRows(res); 
      localStorage.setItem(Constants.cached_goods, JSON.stringify(res));
    });  
  }

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      setIsAtBottom(windowBottom >= docHeight);
    };
    

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollClick = () => {
    if (isAtBottom) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsAtBottom(false);
    } else {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(calculateText);
  };

  const handleMultiplierChange = (event) => {
    setMultiplier(parseInt(event.target.value));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCreateImage = () => {
    let filteredList=[];
    for (const key in quantity) {
      const qtyValue = quantity[key][0];
        filteredList.push({
          name: key,
          quantity: parseInt(qtyValue)
        });
    }
    const updatedFilteredList = filteredList.map(item => {
      const { name } = item;
      let matchingRow = orgRows.find(row => row.name.toLowerCase() == name.toLowerCase());
      const image = matchingRow ? matchingRow.image : null;
      return { ...item, images: image };
    });
    setNewList(updatedFilteredList);
    setIsButtonClicked(true);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = orgRows.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleQuantityChange = (event) => {
    setQuantity({ ...quantity, [event.prodid]: event.target.value });
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


  return (
    <Box sx={{ width: "100%" }} display="flex" flexDirection="column" minHeight="100vh" position="relative">
      <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        marginLeft: '2%',
        //justifyContent: 'center',
        gap: 2,
      }}
    >
      <Typography variant="h5">Enter the multiplier:</Typography>
      <TextField
        id="outlined-number"
        type="number"
        variant="filled"
        color="error"
        defaultValue={5}
        InputLabelProps={{
          shrink: true,
          style: textFieldStyle,
        }}
        InputProps={{
          inputProps: { min: 1, max: 5 },
          style: textFieldStyle,
        }}
        onChange={handleMultiplierChange}
      />
    </Box>
      <Box component="span" sx={{ display: "block", margin: "2%" , color:"red", fontSize: "30px"}}>
        Total Price is: {totalPrice}
      </Box>
      <Paper sx={{ width: "100%", mb: 2, backgroundColor: 'rgba(240, 248, 255, 0.95)' }}>
      <Box sx={{ width: "100%", mb: 2 }}>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          style={{ border: "2px solid black" }}
        />
        <br></br>
        <TableContainer>
        <LazyLoad once>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      // selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell align="left">
                      <LazyLoad >
                        <img
                          alt=""
                          src={row.image}
                          style={{ width: "40px", height: "40px" }}
                          effect="blur"
                          />
                      </LazyLoad>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.level}</TableCell>
                      <TableCell align="left">{row.price}</TableCell>
                      {/* <TableCell align="left">{strtime(row.time)}</TableCell>
                      <TableCell align="left">{row.xp}</TableCell> */}
                      <TableCell align="left">{row.source}</TableCell>
                      {/* <TableCell align="left">
                        {JSON.stringify(row.needs)}
                      </TableCell> */}
                      <TableCell align="left">
                        <TextField
                          id="outlined-number"
                          label={row.name}
                          type="number"
                          //variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ inputProps: { min: 0} }}
                          onChange={(event) =>
                            handleInputChange(event, row.name, row.price)
                          }
                          variant="filled"
                              color="success"
                              focused
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          </LazyLoad>
        </TableContainer>
      </Box>
      </Paper>
      <TextField
          id="filled-multiline-static"
          label=""
          multiline
          rows={10}
          defaultValue={calculateText}
          value={calculateText}
          // value={textareaValue}
          // onChange={handleTextareaChange}
          variant="filled"
          margin="dense"
          // readOnly={false}
          style={{ width: "500px" , backgroundColor:'rgba(240, 248, 255, 0.95)'}}
        />
      <Button variant="contained" color="primary" onClick={handleCopyClick} style={{ width: "250px" }}>
        Copy Text to Clipboard
      </Button>

      {/* <Button variant="contained" color="primary" onClick={handlePopulateNumbers} style={{ width: "250px" }}>
        Populate Numbers
      </Button> */}

      <Container component="main" maxWidth="xs" sx={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(5px)',
        borderRadius: '10px',
        padding: '30px',
        margin: '5% auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <TextField
          label="Number of Columns"
          type="number"
          value={numColumns}
          onChange={(event) => setNumColumns(event.target.value)}
          variant="outlined"
          margin="normal"
          InputProps={{ inputProps: { step: 1, min: 2 } }}
          width={300}
        />
        <Button variant="contained" onClick={handleCreateImage}>
            Create Image
          </Button>
          <div>
          {isButtonClicked && <Collage items={newList} rows={2} columns={numColumns} />}
        </div>
        </Container>

      <Button
        variant="contained"
        color="primary"
        onClick={handleScrollClick}
        style={{
          position: "fixed",
          bottom: "16px",
          right: "16px",
          width: "48px",
    height: "48px",
          borderRadius: "50%",
        }}
      >
        <IconButton >
    {isAtBottom ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
  </IconButton>
      </Button>

    </Box>
  );
}
