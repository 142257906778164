import React, { useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import SearchBar from "material-ui-search-bar";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import LazyLoad from "react-lazy-load";
import { Link } from 'react-router-dom';
import Collage from '../../components/imageMaker/collage'
import Container from '@mui/material/Container';
import { Constants } from "../../config/constants";
import { goods } from "../../goods";


function strtime(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  if (hours === 0 && minutes === 0) return seconds + " sec";
  if (hours === 0 && seconds === 0) return minutes + " min";
  if (seconds === 0 && minutes === 0) return hours + " hr";
  if (hours === 0) return minutes + " min" + seconds + " sec";
  if (seconds === 0) return hours + " hr " + minutes + " min";
  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    const aWithoutNeeds = { ...a[0], needs: null };
    const bWithoutNeeds = { ...b[0], needs: null };
    return aWithoutNeeds.needs === null
      ? -1
      : bWithoutNeeds.needs === null
      ? 1
      : comparator(aWithoutNeeds, bWithoutNeeds);
    //return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "image",
    disableSorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "level",
    numeric: true,
    disablePadding: false,
    label: "Level",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "price",
  },
  {
    id: "time",
    numeric: true,
    disablePadding: false,
    label: "time",
  },
  {
    id: "xp",
    numeric: true,
    disablePadding: false,
    label: "xp",
  },
  {
    id: "source",
    numeric: false,
    disablePadding: false,
    label: "source",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "quantity",
    disableSorting: true,
  },
];

function EnhancedTableHead(props) {
  const {
    //onSelectAllClick,
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSorting ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [rows, setData] = useState([]);
  const [orgRows, setOrgRows] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("level");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(400);
  const [searched, setSearched] = React.useState("");
  const [quantity, setQuantity] = useState({});
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [calculateText, setCalculateText] = React.useState("");
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [newList, setNewList] = useState('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [numColumns, setNumColumns] = useState(5);

  const handleInputChange = (event, productId, image) => {
    if (event.target.value == "") {
      setQuantity({ ...quantity, [productId]: ["0", image] });
    } else {
      setQuantity({ ...quantity, [productId]: [event.target.value, image] });
    }
  };

  useEffect(() => {
    let text = `Your List is- \n`;
    let totalFoods = 0;
    for (const key in quantity) {
      if (parseInt(quantity[key][0]) != 0) {
        text += `\u2022  ${quantity[key][0]} - ${key}\n`;
      }
      totalFoods += parseInt(quantity[key][0]);
    }

    text += `
  Total Foods = ${totalFoods}` 
  setTotalPrice(totalFoods)
    setCalculateText(text);
  }, [quantity]);

  useEffect(() => {
    let data = null;
    if(Constants.takeDataFromFirebase){
      data = localStorage.getItem(Constants.cached_goods);
      data = JSON.parse(data);
    } else {
      data = goods
    }
    if (data) {
      // setAllStates({...allStates,rows: JSON.parse(data)})
      setData(data);
      setOrgRows(data);
    } else {
      const timeoutId = setTimeout(() => {
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  // const getData = () => {
  //   const data =  getGoods();
  //   console.log("Firebase API called on price calculator")
  //   Promise.resolve(data).then((res) => {
  //     setData(res);
  //     setOrgRows(res);
  //     localStorage.setItem(Constants.cached_goods, JSON.stringify(res));
  //   });
  // }

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      setIsAtBottom(windowBottom >= docHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollClick = () => {
    if (isAtBottom) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsAtBottom(false);
    } else {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(calculateText);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = orgRows.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleCreateImage = () => {
    let filteredList=[];
    for (const key in quantity) {
      const qtyValue = quantity[key][0];
        filteredList.push({
          name: key,
          quantity: parseInt(qtyValue)
        });
    }
    const updatedFilteredList = filteredList.map(item => {
      const { name } = item;
      const matchingRow = orgRows.find(row => row.name.toLowerCase() === name.toLowerCase());
      const image = matchingRow ? matchingRow.image : null;
      return { ...item, images: image };
    });
    setNewList(updatedFilteredList);
    setIsButtonClicked(true);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box >
      <Box
        sx={{ width: "100%" }}
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        position="relative"
      >
        <Box
          component="span"
          sx={{
            display: "block",
            margin: "2%",
            color: "red",
            fontSize: "30px",
          }}
        >
          Total quantity is: {totalPrice}
        </Box>
        <Paper sx={{ width: "100%", mb: 2, backgroundColor: 'rgba(240, 248, 255, 0.95)' }} >
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ border: "2px solid black", backgroundColor: '#ffffff' }}
          />
          <br></br>
          <TableContainer >
            <LazyLoad once>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          // selected={isItemSelected}
                        >
                          <TableCell align="left">
                            <LazyLoad>
                              <img
                                alt=""
                                src={row.image}
                                style={{ width: "40px", height: "40px" }}
                                effect="blur"
                              />
                            </LazyLoad>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.level}</TableCell>
                          <TableCell align="left">{row.price}</TableCell>
                          <TableCell align="left">
                            {strtime(row.time)}
                          </TableCell>
                          <TableCell align="left">{row.xp}</TableCell>
                          <TableCell align="left">{row.source}</TableCell>
                          {/* <TableCell align="left">
                        {JSON.stringify(row.needs)}
                      </TableCell> */}
                          <TableCell align="left">
                            <TextField
                              id="outlined-number"
                              label={row.name}
                              type="number"
                              //variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{ inputProps: { min: 0 } }}
                              onChange={(event) =>
                                handleInputChange(event, row.name, row.image)
                              }
                              variant="filled"
                              color="success"
                              focused
                            />
                          </TableCell>
                          {/* <NumberButton /> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </LazyLoad>
          </TableContainer>
        </Paper>
        
        <TextField
          id="filled-multiline-static"
          label=""
          multiline
          rows={10}
          defaultValue={calculateText}
          value={calculateText}
          variant="filled"
          margin="dense"
          style={{ width: "500px" , backgroundColor:'rgba(240, 248, 255, 0.95)'}}
        />
        <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCopyClick}
        style={{ display: 'inline-block', width: '250px', marginBottom: '3%' }}
      >
        Copy Text to Clipboard
      </Button>

      

      <Container component="main" maxWidth="xs" sx={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(5px)',
        borderRadius: '10px',
        padding: '30px',
        margin: '5% auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <TextField
          label="Number of Columns"
          type="number"
          value={numColumns}
          onChange={(event) => setNumColumns(event.target.value)}
          variant="outlined"
          margin="normal"
          InputProps={{ inputProps: { step: 1, min: 2 } }}
          width={300}
        />
        <Button variant="contained" onClick={handleCreateImage}>
            Create Image
          </Button>
          <div>
          {isButtonClicked && <Collage items={newList} rows={2} columns={numColumns} />}
        </div>
        </Container>
    </div>



        <Button
          variant="contained"
          color="primary"
          onClick={handleScrollClick}
          style={{
            position: "fixed",
            bottom: "16px",
            right: "16px",
            width: "48px",
            height: "48px",
            borderRadius: "50%",
          }}
        >
          <IconButton>
            {isAtBottom ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </IconButton>
        </Button>
      </Box>
    </Box>
  );
}
