import React, { useState, useEffect, useRef } from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReactGA from "react-ga4";
import { TextField, Button } from '@mui/material';
import Collage from '../components/imageMaker/collage'
import { Constants } from '../config/constants';
import { Helmet } from 'react-helmet';
import AdSense from '../components/googleAds';
import { goods } from '../goods'

const theme = createTheme();

const ImageMaker = () => {
  const [list, setList] = useState('');
  const [newList, setNewList] = useState('');
  const [rows, setData] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [numColumns, setNumColumns] = useState(5);

  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: "/imageMaker", title: "Image Maker Page Visit" });
  }, []);

  useEffect(() => {
    let data = null;
    if(Constants.takeDataFromFirebase){
      data = localStorage.getItem(Constants.cached_goods);
      data = JSON.parse(data);
    } else {
      data = goods
    }
    if (data) {
      setData(data);
    } else {
      const timeoutId = setTimeout(() => {
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  const handleCreateImage = () => {
    let filteredList = list.replace('Your List is-','');
    filteredList = filteredList.split("\n")
    .filter(item => item.includes("-"))
    .map(item => {
      const [quantity, name] = item.replace(/^\s*•\s*/, "").split(" - ");
      return {name: name, quantity: Number(quantity.trim())};
    });
    const updatedFilteredList = filteredList.map(item => {
      const { name } = item;
      const matchingRow = rows.find(row => row.name.toLowerCase() === name.toLowerCase());
      const image = matchingRow ? matchingRow.image : null;
      return { ...item, images: image };
    });
    setNewList(updatedFilteredList);
    setIsButtonClicked(true);
  };

  const canvasRef = useRef(null);

  return (
    <ThemeProvider theme={theme}>
       <Helmet>
        <title>Hay Day Image Maker</title>
        <meta name="description" content="Create customized images of foods to be traded in the marketplace with the Hay Day Image Maker. Choose from a variety of food items, adjust their quantities, and generate visually appealing images to enhance your trading experience. Share your unique food lists with other players and improve your farming strategies in Hay Day." />
        <meta name="keywords" content="Hay Day, Hay day image maker, hay day goods list generator, hay day price calculator image " />
        <meta name="robots" content="index, follow" />
        </Helmet>
      <CssBaseline />
      <AdSense />
      <Container component="main" maxWidth="xs" sx={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(5px)',
        borderRadius: '10px',
        padding: '30px',
        margin: '5% auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <h4>Enter your list from listmaker here</h4>
        <TextField
          label="List"
          multiline
          rows={4}
          value={list}
          defaultValue={"sd"}
          onChange={(event) => setList(event.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="Number of Columns"
          type="number"
          value={numColumns}
          onChange={(event) => setNumColumns(event.target.value)}
          variant="outlined"
          margin="normal"
          InputProps={{ inputProps: { step: 1, min: 2 } }}
          fullWidth
        />
        
          <Button variant="contained" onClick={handleCreateImage}>
            Create Image
          </Button>
          <div>
          {isButtonClicked && <Collage items={newList} rows={2} columns={numColumns} />}
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default ImageMaker;
