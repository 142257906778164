import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { TextField, Button, Typography } from '@material-ui/core';
import { getGood, getGoods, createMessage } from "../api/fetch";
import ReactGA from "react-ga4";
import AdSense from '../components/googleAds';

const theme = createTheme();

const ContactUs = () => {

  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: "/contactUs", title: "Contact Us Page Visit" });
    }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const handleSubmit = (event) => {

    event.preventDefault();

    // Reset error states
    setNameError(false);
    setEmailError(false);
    setMessageError(false);

    if (name!="") {
      setNameError(true);
    }

    // Check if the email is in the correct format using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
    }

    if (!message != "") {
      setMessageError(true);
    }

    console.log(nameError)
    // Check if any errors exist
    if (nameError || emailError || messageError) {
      return;
    }else{

    const messageObject = {
      name: name,
      email: email,
      message: message,
      date: Date.now()
    };

    const data = createMessage(messageObject);
    console.log("User sent a message on contact us page");
    Promise.resolve(data).then((res) => {
      setName("");
      setEmail("");
      setMessage("");
      setIsMessageSent(true);
    });
  }
  };

  // Disable the "Send" button if any of the fields are empty or if the email is not in the correct format
  const isFormValid = name && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && message;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AdSense />

        <Container component="main" maxWidth="xs" sx={{ 
			backgroundColor: 'rgba(255, 255, 255, 0.8)',
			backdropFilter: 'blur(5px)',
			borderRadius: '10px',
			padding: '30px',
			margin: '5% auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
      height: '90vh'
		  }}>
          <Typography variant="h5" component="h1" gutterBottom>
            Contact Us
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={name}
              onChange={(event) => setName(event.target.value)}
              error={nameError}
              helperText={nameError && "Please enter your name."}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              error={emailError}
              helperText={emailError && "Please enter a valid email address."}
            />
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              error={messageError}
              helperText={messageError && "Please enter your message."}
            />
            <Button type="submit"
         variant="contained" color="primary">
        Send
      </Button>
    </form>
    {isMessageSent && (
      <Typography variant="body1" style={{ color: 'green', marginTop: '1rem' }}>
        Message sent successfully!
      </Typography>
    )}
  </Container>

</ThemeProvider>
);
};
export default ContactUs;