import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReactGA from "react-ga4";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box, Avatar } from "@mui/material";
import AdSense from '../components/googleAds';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const initialBuildings = [
  { value: "GroceryStore", label: "Grocery Store", maxLevel: 38 },
  { value: "Cinema", label: "Cinema", maxLevel: 38 },
  { value: "BedAndBreakfast", label: "Bed and Breakfast", maxLevel: 38 },
  { value: "Diner", label: "Diner", maxLevel: 38 },
  { value: "Spa", label: "Spa", maxLevel: 38 },
  { value: "GiftShop", label: "Gift Shop", maxLevel: 38 },
  { value: "BeachCafe", label: "Beach Cafe", maxLevel: 38 },
  { value: "PersonalTrain", label: "Personal Train", maxLevel: 19 },
  { value: "TownHall", label: "Town Hall", maxLevel: 12 },
  { value: "TrainStation", label: "Train Station", maxLevel: 12 },
];

const defaultToolsNeeded = {
  GroceryStore: ["bolt", "duct tape", "stone block"],
  Cinema: ["hammer", "nail", "wood panel"],
  Diner: ["paint bucket", "plank", "screw"],
  BedAndBreakfast: ["brick", "hand drill", "paint bucket"],
  Spa: ["brick", "stone block", "tar bucket"],
  GiftShop: ["hammer", "stone block", "tar bucket"],
  BeachCafe: ["hammer", "hand drill", "tar bucket"],
  PersonalTrain: [
    "refined coal",
    "iron bar",
    "silver bar",
    "gold bar",
    "platinum bar",
  ],
  TownHall: ["hammer", "paint bucket", "stone block"],
  TrainStation: ["brick", "hand drill", "tar bucket"],
};

const ToolImages = ({ toolsNeeded, images }) => {
  return (
    <Grid container spacing={1}>
      {Object.keys(toolsNeeded).map((tool, index) => (
        <Grid item xs={4} key={index}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              variant="square"
              alt={tool}
              src={images.find((img) => img.name === tool)?.image}
              sx={{ width: 60, height: 60 }}
            />
            <Typography variant="subtitle2" color="textSecondary">
              {toolsNeeded[tool]}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};



const TownToolsCalculator = () => {
  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: "/townToolsCalculator", title: "Town Tools Calculator Page Visit" });
    }, []);

  const classes = useStyles();
  const [building, setBuilding] = useState("");
  const [currentLevel, setCurrentLevel] = useState("");
  const [upgradeLevel, setUpgradeLevel] = useState("");
  const [buildingList, setBuildingList] = useState([]);
  const [buildings, setBuildings] = useState(initialBuildings);
  const [images, setImages] = useState([]);
  const [isError, setIsError] = useState(false);
  const [toolsNeeded, setToolsNeeded] = useState({});

  function checkForErrors(buildingList) {
    let isErrorr = false;

    buildingList.forEach((item) => {
      const { building, currentLevel, upgradeLevel } = item;
      const buildingData = initialBuildings.find((b) => b.value === building);
      const currentLevelNum = Number(currentLevel);
      const upgradeLevelNum = Number(upgradeLevel);

      if (
        currentLevelNum >= upgradeLevelNum ||
        upgradeLevelNum > buildingData.maxLevel
      ) {
        isErrorr = true;
      }
    });

    return isErrorr;
  }

  function calculateToolsForBuildings(buildingList) {
    setToolsNeeded({});
    buildingList.forEach((item) => {
      const { building } = item;
      switch (building) {
        case "GroceryStore":
        case "Cinema":
        case "BedAndBreakfast":
        case "Diner":
        case "Spa":
        case "GiftShop":
        case "BeachCafe":
        case "TownHall":
        case "TrainStation":
          getToolsForType1(item);
          break;
        case "PersonalTrain":
          getToolsForType2(item);
          break;
        default:
          console.log(`No tools needed for ${building}`);
      }
    });
  }

  function getToolsForType1(item) {
    const { building, currentLevel, upgradeLevel } = item;
    const currentLevelNum = Number(currentLevel);
    const upgradeLevelNum = Number(upgradeLevel);
    const tools = defaultToolsNeeded[building];
    let totalTools = 0;
    for (
      let i = Number(currentLevelNum) + 1;
      i <= Number(upgradeLevelNum);
      i++
    ) {
      totalTools += i - 1;
    }
    setToolsNeeded((prevState) => {
      const updatedTools = { ...prevState };

      for (let i = 0; i < tools.length; i++) {
        const tool = tools[i];
        const count = totalTools;

        if (updatedTools.hasOwnProperty(tool)) {
          updatedTools[tool] += count;
        } else {
          updatedTools[tool] = count;
        }
      }

      return updatedTools;
    });
  }

  function getToolsForType2(item) {
    const { building, currentLevel, upgradeLevel } = item;
    const currentLevelNum = Number(currentLevel);
    const upgradeLevelNum = Number(upgradeLevel);

    let y = 0;
    let toolNeeded = "";
    const tools = {
      "refined coal": 0,
      "iron bar": 0,
    };

    for (
      let i = Number(currentLevelNum) + 1;
      i <= Number(upgradeLevelNum);
      i++
    ) {
      if (i === 2 || i === 3 || i === 4) {
        y = 5;
      } else if (i === 5 || i === 6 || i === 7) {
        y = 10;
      } else if (i === 8 || i === 9 || i === 10) {
        y = 15;
      } else if (i === 11 || i === 12 || i === 13) {
        y = 20;
      } else if (i === 14 || i === 15 || i === 16) {
        y = 25;
      } else if (i === 17 || i === 18 || i === 19) {
        y = 30;
      }

      if (i === 2 || i === 5 || i === 8 || i === 11 || i === 14 || i === 17) {
        toolNeeded = "silver bar";
      } else if (
        i === 3 ||
        i === 6 ||
        i === 9 ||
        i === 12 ||
        i === 15 ||
        i === 18
      ) {
        toolNeeded = "gold bar";
      } else if (
        i === 4 ||
        i === 7 ||
        i === 10 ||
        i === 13 ||
        i === 16 ||
        i === 19
      ) {
        toolNeeded = "platinum bar";
      }

      tools["refined coal"] += y;
      tools["iron bar"] += y;
      if (tools.hasOwnProperty(toolNeeded)) {
        tools[toolNeeded] += y;
      } else {
        tools[toolNeeded] = y;
      }
    }

    setToolsNeeded((prevState) => {
      const updatedTools = { ...prevState };

      for (const [tool, count] of Object.entries(tools)) {
        if (updatedTools.hasOwnProperty(tool)) {
          updatedTools[tool] += count;
        } else {
          updatedTools[tool] = count;
        }
      }

      return updatedTools;
    });
  }

  useEffect(() => {
    const data = localStorage.getItem("images");
    if (data) {
      setImages(JSON.parse(data));
    } else {
      const timeoutId = setTimeout(() => {
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  useEffect(() => {
    const hasError = checkForErrors(buildingList);
    setIsError(hasError);
    if (!hasError) {
      calculateToolsForBuildings(buildingList);
    }
  }, [buildingList]);

  const handleAddBuilding = (e) => {
    e.preventDefault();
    const buildingToAdd = {
      building: building,
      currentLevel: currentLevel,
      upgradeLevel: upgradeLevel,
    };
    setBuildingList([...buildingList, buildingToAdd]);
    setBuilding("");
    setCurrentLevel("");
    setUpgradeLevel("");
    setBuildings(buildings.filter((b) => b.value !== building));
  };

  const handleEditBuilding = (index) => {
    const buildingToEdit = buildingList[index];
    setBuilding(buildingToEdit.building);
    setCurrentLevel(buildingToEdit.currentLevel);
    setUpgradeLevel(buildingToEdit.upgradeLevel);
    setBuildingList(buildingList.filter((b, i) => i !== index));
    setBuildings([
      ...buildings,
      { value: buildingToEdit.building, label: buildingToEdit.building },
    ]);
  };

  const handleDeleteBuilding = (index) => {
    const buildingToDelete = buildingList[index];
    setBuildingList(buildingList.filter((b, i) => i !== index));
    setBuildings([
      ...buildings,
      { value: buildingToDelete.building, label: buildingToDelete.building },
    ]);
  };

  return (
    <ThemeProvider theme={theme}>
      		                <Helmet>
        <title>Hay Day Town Tools Calculator</title>
        <meta name="description" content="Calculate the tools and upgrades required to enhance town buildings in Hay Day with the Town Tools Calculator. Determine the number of building materials, such as bricks, planks, and screws, needed to upgrade buildings like the grocery store, cinema, spa, and more. Efficiently plan your town upgrades and manage your resources to unlock new features and expand your town in Hay Day." />
        <meta name="keywords" content="Hay Day town tools, hay day town tools calculator, hay day town upgrades, grocery cinema" />
        <meta name="robots" content="index, follow" />
        </Helmet>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(5px)',
          borderRadius: '10px',
          padding: '30px',
          marginTop: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom:4
          
        }}
      >
        <CssBaseline />
        <AdSense />
        <Box
  sx={{
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    padding: 3,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginBottom: 4,
  }}
>
  <Typography
    variant="h5"
    sx={{
      fontSize: 28,
      fontWeight: 800,
      color: '#4A4A4A',
      marginBottom: 2,
    }}
  >
    Town Tools Calculator
  </Typography>
  <Typography
    variant="body1"
    sx={{
      fontSize: 18,
      lineHeight: 1.5,
      color: '#6B6B6B',
    }}
  >
   Select the building and enter the current and final level you want to upgrade it to. Click on Add button to add the building to your list. The tools shown will be the total tools required to upgrade all buildings.
  </Typography>
</Box>

        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
   

          <div style={{ marginTop: 7 }}>
            <form onSubmit={handleAddBuilding}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="building-label">Building</InputLabel>
                <Select
                  labelId="building-label"
                  id="building"
                  value={building}
                  onChange={(e) => setBuilding(e.target.value)}
                >
                  {buildings.map((building) => (
                    <MenuItem
                      key={building.value}
                      value={building.value}
                      style={{
                        fontWeight: "bold",
                        color: "#f50057c9",
                        margin: "10px 0",
                        width: "100%",
                      }}
                      margin="normal"
                      required
                      fullWidth
                    >
                      {building.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Current Level"
                type="number"
                value={currentLevel}
                InputProps={{ inputProps: { step: 1, min: 1, max: 39 } }}
                onChange={(e) => setCurrentLevel(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Upgrade To Level"
                type="number"
                value={upgradeLevel}
                InputProps={{ inputProps: { step: 1, min: 1, max: 39 } }}
                onChange={(e) => setUpgradeLevel(e.target.value)}
              />
              <Button variant="contained" color="primary" type="submit">
                Add
              </Button>
            </form>
            {buildingList.length > 0 && (
              <List>
                {buildingList.map((buildingItem, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        initialBuildings.find(
                          (b) => b.value === buildingItem.building
                        )?.label || "Unknown Building"
                      }
                      secondary={`Current Level: ${buildingItem.currentLevel}, Upgrade Level: ${buildingItem.upgradeLevel}`}
                      sx={{ marginRight: 4 }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleEditBuilding(index)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteBuilding(index)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </div>

          {isError && (
            <Box bgcolor="#ffcccc" p={2} borderRadius={4} mb={2}>
              <Typography variant="body1" color="textSecondary">
                There is an error in the level you entered
              </Typography>
            </Box>
          )}
          {!isError && (
            <Box mt={2}>
              <Typography variant="h6">Tools Needed:</Typography>
              <ToolImages toolsNeeded={toolsNeeded} images={images} />
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TownToolsCalculator;
