export const Constants = {
    cached_goods : "Goods_23_sep_2024",
    goods_data_db: "Goods_23_sep_2024",
    old_cache: [
        "cachedGoods",
        "newCachedGoods",
        "cacheHDImages",
        "cachedGood",
        "newGoods",
        "goods",
        "Goods_2906",
        "Goods_26_sep"
    ],
    takeDataFromFirebase: false
}