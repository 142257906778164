import React from 'react';

const BemsCalculator = () => {
return (
	<div
	
	>
	<h1>BemsCalculator page here</h1>
	</div>
);
};

export default BemsCalculator;