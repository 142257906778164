import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { Navbar } from "../components";
import ResponsiveAppBar from "../components/Navbar/index2";
import { goods } from "../goods";
import {
  Home,
  BarnSizeCalculator,
  BemsCalculator,
  DecoCostCalculator,
  FoodListMaker,
  ContactUs,
  PriceCalculator,
  GoodsList,
  TownToolsCalculator,
  GoodsItem,
  ImageMaker
} from "../pages";
import { getGood, getGoods, getHDImages } from "../api/fetch";
import Footer from "../components/Footer/footer";
import ReactGA from "react-ga4";
import { Constants } from "../config/constants";

function App() {
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    // ReactGA.initialize("G-LZ55FW93G8");
    // ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page Visit" });

    let oldCache = Constants.old_cache;
    for(let cache of oldCache){
      localStorage.removeItem(cache);
    }

    let cacheData = localStorage.getItem(Constants.cached_goods);
    const cacheImages = localStorage.getItem("images")

    if(Constants.takeDataFromFirebase){
      if (cacheData) {
        setData(JSON.parse(cacheData));
      } else {
        getData();
      }
    }

    if (cacheImages) {
      setImages(JSON.parse(cacheData));
    } else {
      getImagesData();
    }
    
  }, []);

  const getData = () => {
    const data = getGoods();
    console.log("Firebase API called on main app");
    Promise.resolve(data).then((res) => {
      setData(res);
      localStorage.setItem(Constants.cached_goods, JSON.stringify(res));
    });
  };

  const getImagesData = () => {
    const data = getHDImages();
    console.log("Firebase API called on main app for images");
    Promise.resolve(data).then((res) => {
      setImages(res);
      localStorage.setItem("images", JSON.stringify(res));
    });
  };

  return (
    <div className="App" style={{
      width: '100%',
      // height: '100vh',
      backgroundImage: `url(https://wallpaperaccess.com/full/3022015.jpg)`,
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }}>
      <Router>
        <ResponsiveAppBar />
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" exact element={<Home goods={data} />} />
         
          <Route path="/home" exact element={<Home goods={data} />} />
          <Route path="/goodsList" exact element={<GoodsList goods={data} images={images} />} />
          <Route
            path="/barnSizeCalculator"
            exact
            element={<BarnSizeCalculator />}
          />
          <Route path="/bemsCalculator" element={<BemsCalculator />} />
          <Route path="/decoCostCalculator" element={<DecoCostCalculator />} />
          <Route
            path="/foodListMaker"
            element={<FoodListMaker goods={data} />}
          />
          <Route path="/contactUs" element={<ContactUs  />} />
          <Route
            path="/priceCalculator"
            element={<PriceCalculator goods={data} />}
          />
          <Route path="/townToolsCalculator" element={<TownToolsCalculator images={images}/>} />
          <Route path="/goods/:goodsName" element={<GoodsItem/>} />
          <Route path="/imageMaker" element={<ImageMaker/>} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
