import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Link, Typography, Button } from '@material-ui/core';
import FacebookIcon from './cib-facebook.svg';
import DiscordIcon from './cib-discord.svg';
import InstagramIcon from './cib-instagram.svg';
import MessengerIcon from './cib-messenger.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#1a1a1a',
    color: theme.palette.common.white,
  },
  icon: {
    color: 'white',
  },
  text: {
    marginTop: theme.spacing(1),
  },
  button: {
    width: '200px',
    height: '40px',
    margin: theme.spacing(2),
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Link href="https://www.facebook.com/maskararaghav/" target="_blank" rel="noopener">
                <img src={FacebookIcon} alt="Facebook" className={classes.icon} />
              </Link>
            </Grid>
            <Grid item>
              <Link href="https://www.instagram.com/raghavmaskara/" target="_blank" rel="noopener">
                <img src={InstagramIcon} alt="Instagram" className={classes.icon} />
              </Link>
            </Grid>
            <Grid item>
              <Link href="https://m.me/maskararaghav" target="_blank" rel="noopener">
                <img src={MessengerIcon} alt="Messenger" className={classes.icon} />
              </Link>
            </Grid>
            <Grid item>
              <Link href="https://discord.gg/PxCCnnc529" target="_blank" rel="noopener">
                <img src={DiscordIcon} alt="Discord" className={classes.icon} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="/contactUs"
            className={classes.button}
          >
            Contact Us
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h6" align="center" className={classes.text}>
            Created by Raghav Maskara
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
