import React, {useEffect} from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AdSense from '../components/googleAds';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';

const theme = createTheme();

const pages = [
	{
	  name: 'Goods List',
	  urlLink: '/goodsList',
	  description: 'Browse the list of goods available'
	},
	{
	  name: 'Price Calculator',
	  urlLink: '/priceCalculator',
	  description: 'Calculate prices for selling in marketplace'
	},
	{
	  name: 'Food List Maker',
	  urlLink: '/foodListMaker',
	  description: 'Create a list and image of Foods you want to trade'
	},
	{
	  name: 'Barn Size Calculator',
	  urlLink: '/barnSizeCalculator',
	  description: 'Calculate the tools needed to upgrade your barn'
	},
	{
		name: 'Town Tools Calculator',
		urlLink: '/townToolsCalculator',
		description: 'Calculate the tools needed to upgrade your town'
	},
	{
	  name: 'Deco Cost Calculator',
	  urlLink: '/decoCostCalculator',
	  description: 'Calculate the cost of decorating your farm'
	},
	{
	  name: 'Contact Us',
	  urlLink: '/contactUs',
	  description: 'Get in touch with us for support or inquiries'
	}
  ];

//   const glassCardStyle = {
// 	background: 'rgba(255, 255, 255, 0.1)',
// 	boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
// 	borderRadius: '15px',
// 	backdropFilter: 'blur(10px)',
// 	border: '1px solid rgba(255, 255, 255, 0.2)',
//   };
  
//   const glassButtonStyle = {
// 	background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
// 	boxShadow: '0 3px 5px 2px rgba(255, 105, 135, 0.3)',
// 	borderRadius: '10px',
// 	color: 'white',
// 	fontSize: '0.875rem',
// 	padding: '6px 12px',
//   };
  
const glassCardStyle = {
	backgroundColor: "rgba(255, 255, 255, 0.1)",
	borderRadius: "15px",
	padding: "20px",
	backdropFilter: "blur(20px)",
	border: "1px solid rgba(255, 255, 255, 0.2)",
	height: "250px"
  };
  
  const glassButtonStyle = {
	background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
	borderRadius: "15px",
	padding: "5px 15px",
	backdropFilter: "blur(10px)",
	border: "1px solid rgba(255, 255, 255, 0.2)",
	color: "white",
  };

  const Home = () => {

	useEffect(() => {
		ReactGA.initialize("G-LZ55FW93G8");
		ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page Visit" });
	  }, []);

	return (
		<>
	<Helmet>
  <title>Hay Day Calculator - Home</title>
  <meta name="description" content="Welcome to Hay Day Calculator. Explore a variety of features and tools to enhance your gaming experience in Hay Day. Get access to goods list, price calculator, food list maker, barn size calculator, town tools calculator, deco cost calculator, and more." />
  <meta name="keywords" content="Hay Day Calculator, Hay Day, gaming, goods list, price calculator, food list maker, barn size calculator, town tools calculator, deco cost calculator" />
  <meta name="robots" content="index, follow" />
</Helmet>

		
	  <ThemeProvider theme={theme}>
		<Container component="main" maxWidth="md" sx={{marginBottom: "5%"}}>
		  <CssBaseline />
		  <div
			style={{
			  display: 'flex',
			  flexDirection: 'column',
			  minHeight: '90vh',
			}}
		  >
			<AdSense />
			<Typography component="h1" variant="h3" align="center" gutterBottom margin={5} sx={{ 
  fontFamily: 'Pacifico, cursive',
  textShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
  color: '#FF5733',
}}>
  Hay Day Calculator
</Typography>



<Grid container spacing={4} justifyContent="center">
      {pages.map((page, index) => (
        <Grid item key={index} xs={12} sm={6} md={4}>
          <Card style={glassCardStyle}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {page.name}
              </Typography>
              <Typography color="textSecondary">
                {page.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                style={glassButtonStyle}
                size="small"
                component={Link}
                to={page.urlLink}
              >
                Visit
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
		  </div>
		</Container>
	  </ThemeProvider>
	  </>
	);
  };
  
  export default Home;