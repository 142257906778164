import React from "react";
import { useEffect, useState } from "react";
import DispData from "../components/GoodsListDisplay/dispdata";
import MediaCard from "../components/Card/card";
import { getGoods, getHDImages } from "../api/fetch";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SearchBar from "material-ui-search-bar";
import ReactGA from "react-ga4";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EnhancedTable from "../components/GoodsListDisplay/goodsTable";
import { Constants } from "../config/constants";
import { Helmet } from 'react-helmet';
import AdSense from '../components/googleAds';
import { goods } from '../goods'

import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Typography,
  Button,
  Menu,
  Box
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
  slider: {
    "& .MuiSlider-rail": {
      backgroundColor: "#C4C4C4",
    },
    "& .MuiSlider-track": {
      backgroundColor: "#3F51B5",
    },
    "& .MuiSlider-thumb": {
      backgroundColor: "#3F51B5",
    },
  },
}));

const theme = createTheme();

const GoodsList = (props) => {
  const classes = useStyles();

  const handleSortChange = (event) => {
    const [field, order] = event.target.value.split("-");
    setSort({ field, order });
  };

  const [data, setData] = useState([]);
  const [orgRows, setOrgRows] = useState([]);
  const [searched, setSearched] = React.useState("");
  const [sort, setSort] = useState({ field: "name", order: "asc" });
  const [checkedSources, setCheckedSources] = useState([]);
  const sourceSet = new Set();
  orgRows.forEach((row) => sourceSet.add(row.source));
  const [anchorEl, setAnchorEl] = useState(null);
  const [goodsImages, setGoodsImages] = useState({});
  const [levelRange, setLevelRange] = useState([1, 125]);
  const [isTable, setIsTable] = useState(false);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({
      hitType: "pageview",
      page: "/goodsList",
      title: "Goods List Page Visit",
    });
    let data = null;
    if(Constants.takeDataFromFirebase){
      data = localStorage.getItem(Constants.cached_goods);
      data = JSON.parse(data);
    } else {
      data = goods
    }

    if (data) {
      setData(data);
      setOrgRows(data);
    } else {
      getGoodsData();
    }
    const cacheImagesData = localStorage.getItem("images");
    if (cacheImagesData) {
      const parsedImagesData = JSON.parse(cacheImagesData);
      const newGoodsImages = {};
      parsedImagesData.map(item => {
        const { name, image } = item;
        newGoodsImages[name] = image;
      });
      setGoodsImages(prevState => ({ ...prevState, ...newGoodsImages }));
    } else {
      getImagesData();
    }
  }, []);

  useEffect(() => {
    const newGoodsImages = {};
    data.map(item => {
      const { name, image } = item;
      newGoodsImages[name] = image;
    });
    setGoodsImages(prevState => ({ ...prevState, ...newGoodsImages }));
  }, [data]);

  const getGoodsData = () => {
    const data = getGoods();
    console.log("Firebase API called on goods list page for goods");
    Promise.resolve(data).then((res) => {
      setData(res);
      setOrgRows(res);
      localStorage.setItem(Constants.cached_goods, JSON.stringify(res));
    });
  };

  const getImagesData = () => {
    const data = getHDImages();
    console.log("Firebase API called on goods list page for images");
    Promise.resolve(data).then((res) => {
      const newGoodsImages = {};
      res.map(item => {
        const { name, image } = item;
        newGoodsImages[name] = image;
      });
      setGoodsImages(prevState => ({ ...prevState, ...newGoodsImages }));
      localStorage.setItem("images", JSON.stringify(res));
    });
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = orgRows.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const sortCards = (cards) => {
    const { field, order } = sort;
    const compare = (a, b) => {
      let aValue, bValue;
      switch (field) {
        case "name":
        case "source":
          aValue = a[field].toLowerCase();
          bValue = b[field].toLowerCase();
          break;
        case "level":
        case "price":
        case "time":
        case "xp":
          aValue = Number(a[field]);
          bValue = Number(b[field]);
          break;
        default:
          aValue = a[field];
          bValue = b[field];
          break;
      }
      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    };
    return [...cards].sort(compare);
  };

  const handleCheckedSource = (event) => {
    const source = event.target.value;
    if (event.target.checked) {
      setCheckedSources([...checkedSources, source]);
    } else {
      setCheckedSources(checkedSources.filter((src) => src !== source));
    }
  };

  

  const handleLevelRangeChange = (event, newValue) => {
    console.log(goodsImages);
    setLevelRange(newValue);
  };

  const filterCards = (cards) => {
    let result = cards;
    if (checkedSources.length > 0) {
      result = result.filter((card) => checkedSources.includes(card.source));
    }
    result = result.filter(
      (card) => card.level >= levelRange[0] && card.level <= levelRange[1]
    );
    return result;
  };

  const handleToggle = () => {
    setIsTable(!isTable);
  };

  const sortedSources = Array.from(sourceSet).sort();

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Hay Day Goods List</title>
        <meta name="description" content="Browse the list of goods available in Hay Day Calculator. Find detailed information about each good, including its name, level, price, production time, XP value, and source. Use the search bar and filters to find specific goods. View the goods as cards or in a table format. Enhance your Hay Day gaming experience with Hay Day Calculator." />
        <meta name="keywords" content="Hay Day Goods List, Goods List, Hay day foods, Hay day items, Hay day goods, Hay day, Hay day tools, Hay day goods details description " />
        <meta name="robots" content="index, follow" />
        </Helmet>
      <div style={{ marginBottom: "100" }}>
        <CssBaseline />
        <AdSense />
        <Grid
          container
          spacing={2}
          alignItems="center"
          marginTop={3}
          justifyContent="space-evenly"
        >
          <Grid item>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              style={{
                border: "2px solid black",
                width: "400px",
                height: "40px",
                fontSize: "14px",
                borderRadius: "50px",
                margin: "0 auto",
              }}
            />
          </Grid>
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="sort-by-label">Sort by</InputLabel>
              <Select
                labelId="sort-by-label"
                id="sort-by-select"
                value={`${sort.field}-${sort.order}`}
                onChange={handleSortChange}
                label="Sort by"
              >
                <MenuItem value="name-asc">Name (A-Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z-A)</MenuItem>
                <MenuItem value="level-asc">Level (Low to High)</MenuItem>
                <MenuItem value="level-desc">Level (High to Low)</MenuItem>
                <MenuItem value="price-asc">Price (Low to High)</MenuItem>
                <MenuItem value="price-desc">Price (High to Low)</MenuItem>
                <MenuItem value="time-asc">Time (Low to High)</MenuItem>
                <MenuItem value="time-desc">Time (High to Low)</MenuItem>
                <MenuItem value="xp-asc">XP (Low to High)</MenuItem>
                <MenuItem value="xp-desc">XP (High to Low)</MenuItem>
                <MenuItem value="source-asc">Source (A-Z)</MenuItem>
                <MenuItem value="source-desc">Source (Z-A)</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <Typography id="level-range-slider" gutterBottom>
              Level Range:
            </Typography>
            <Slider
              className={classes.slider}
              value={levelRange}
              onChange={handleLevelRangeChange}
              valueLabelDisplay="auto"
              aria-labelledby="level-range-slider"
              min={1}
              max={125}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2">{levelRange[0]}</Typography>
              <Typography variant="body2">{levelRange[1]}</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Button
              onClick={handleFilterClick}
              sx={{
                color: "white",
                backgroundColor: "#1976d2",
                borderRadius: 25,
                padding: "8px 24px",
                fontSize: 14,
                "&:hover": {
                  backgroundColor: "#0d47a1",
                },
              }}
            >
              Source Filter
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleFilterClose}
            >
              <FormGroup sx={{ padding: "10px" }}>
  <FormControlLabel
    control={
      <Checkbox
        value="All"
        onChange={(e) =>
          setCheckedSources(
            e.target.checked ? Array.from(sourceSet) : []
          )
        }
      />
    }
    label="All"
  />
  {/* convert the Set to an array, sort it, and map over it */}
  {sortedSources.map((source) => (
    <FormControlLabel
      key={source}
      control={
        <Checkbox
          value={source}
          checked={checkedSources.includes(source)}
          onChange={handleCheckedSource}
        />
      }
      label={source}
    />
  ))}
</FormGroup>
            </Menu>
          </Grid>

          <Grid item>
          <Button
  onClick={handleToggle}
  sx={{
    borderRadius: "20px",
    padding: "8px 16px",
    backgroundColor: "#1876d2",
    color: "#fff",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1876d2",
      color: "#fff",
    },
    "&.active": {
      backgroundColor: "#2196f3",
      color: "#fff",
    },
  }}
  className={isTable ? "" : "active"}
>
  {isTable ? "View Cards" : "View Table"}
</Button>
          </Grid>
        </Grid>



        {isTable ? (
          <EnhancedTable goods={filterCards(sortCards(data))} goodsImages={goodsImages} />
        ) : (
          <DispData
            goods={filterCards(sortCards(data))}
            goodsImages={goodsImages}
          />
        )}


      </div>
    </ThemeProvider>
  );
};

export default GoodsList;
