import React, {useEffect} from "react";
import EnhancedTable from "../components/listMakerTable/table2"
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import ReactGA from "react-ga4";
import AdSense from '../components/googleAds';
const theme = createTheme();
const FoodListMaker = (props) => {
	useEffect(() => {
		ReactGA.initialize("G-LZ55FW93G8");
		ReactGA.send({ hitType: "pageview", page: "/foodListMaker", title: "Food List Maker Page Visit" });
	  }, []);
	return (
		<ThemeProvider theme={theme}>
		  <Container component="main">
			<CssBaseline />
			<AdSense />
			<EnhancedTable goods={props.goods} />
		  </Container>
		</ThemeProvider>
	  );
};

export default FoodListMaker;