import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ListItemIcon } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GoogleTranslate from "../googleTranslate"

const pages = [
  {
      name: 'Home',
      urlLink: '/home'
  },
  {
    name: 'Goods List',
    urlLink: '/goodsList'
  },
  {
      name: 'Price Calculator',
      urlLink: '/priceCalculator'
  },
  {
      name: 'Food List Maker',
      urlLink: '/foodListMaker'
  },{
    name: 'Goods Image Maker',
    urlLink: '/imageMaker'
  },
  {
    name: 'Barn Size Calculator',
    urlLink: '/barnSizeCalculator'
},{
  name: 'Contact Us',
  urlLink: '/contactUs'
}
]

const calculatorPages = [
  {
      name: 'Town Tools Calculator',
      urlLink: '/townToolsCalculator'
  },
  {
      name: 'Deco Cost Calculator',
      urlLink: '/decoCostCalculator'
  },
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElCalculator, setAnchorElCalculator] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenCalculatorMenu = (event) => {
    setAnchorElCalculator(event.currentTarget);
  };

  const handleCloseCalculatorMenu = () => {
    setAnchorElCalculator(null);
  };
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/home"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            HAYDAY
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
            edge="end"
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu} component={Link} to={page.urlLink}>
                <Typography textAlign="center" href={page.urlLink} >{page.name}</Typography>
              </MenuItem>
            ))}
            <MenuItem onClick={handleOpenCalculatorMenu}>
  <Typography textAlign="center">Other Tools</Typography>
  <ListItemIcon>
    <ArrowRightIcon />
  </ListItemIcon>
</MenuItem>
            <Menu
              id="calculators-menu"
              anchorEl={anchorElCalculator}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElCalculator)}
              onClose={handleCloseCalculatorMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {calculatorPages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseCalculatorMenu} component={Link} to={page.urlLink}>
                  <Typography textAlign="center" href={page.urlLink} >{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Menu>
        </Box>
  
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page) => (
            <Button
              key={page.name}
              onClick={handleCloseNavMenu}
              component={Link} 
              to={page.urlLink}
              sx={{ my: 2, color: 'white', display: 'block' }} 
            >
              {page.name}
            </Button>
          ))}
          <Button
  aria-controls="calculators-menu-md"
  aria-haspopup="true"
  onClick={handleOpenCalculatorMenu}
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    my: 2,
    color: 'white',
    width: 'fit-content',
    borderRadius: '4px',
    backgroundColor: 'primary.main',
    '&:hover': {
      backgroundColor: 'primary.dark',
    },
  }}
>
  Other Tools
  <ArrowDropDownIcon />
</Button>


          <Menu
            id="calculators-menu-md"
            anchorEl={anchorElCalculator}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElCalculator)}
            onClose={handleCloseCalculatorMenu}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            {calculatorPages.map((page) => (
              <MenuItem key={page.name} onClick={handleCloseCalculatorMenu} component={Link} to={page.urlLink}>
                <Typography textAlign="center" href={page.urlLink} >{page.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
       {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
                 <GoogleTranslate />
      </Toolbar>
    </Container>
  </AppBar>
  );  
}
export default ResponsiveAppBar;