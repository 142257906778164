import React, {useEffect} from "react";
import EnhancedTable from "../components/table/table2";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import AdSense from '../components/googleAds';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
const theme = createTheme();
const PriceCalculator = (props) => {

  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: "/priceCalculator", title: "Price Calculator Page Visit" });
    }, []);

  return (
    
    <ThemeProvider theme={theme}>
          <Helmet>
        <title>Hay Day Price Calculator</title>
        <meta name="description" content="Calculate the prices of food items to sell and trade in the marketplace with the Hay Day Price Calculator. Get accurate pricing information based on various factors such as production time, xp, cost and market demand. Optimize your selling strategies and make informed decisions to maximize profits in Hay Day. Sell and trade on discord and facebook by making Foods list." />
        <meta name="keywords" content="Hay Day, Hay day price calculator, hay day calculator, calculator, hay day marketplace calculator, hay day discord calculator, hay day foods list maker, hay day discord" />
        <meta name="robots" content="index, follow" />
        </Helmet>
      <Container component="main">
        <CssBaseline />
        <AdSense />
        <EnhancedTable goods={props.goods} />
      </Container>
    </ThemeProvider>
  );
};

export default PriceCalculator;
