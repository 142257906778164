import MediaCard from "../Card/card";
import { Grid } from "@mui/material";
const DispData = ({goods, goodsImages}) => {

    if(goods.length === 0) return <div> Loading Data</div>;
      return(
        <Grid container justifyContent={'space-evenly'} >
  {goods.map((good, index) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
        <MediaCard
          name={good.name}
          level={good.level}
          price={good.price}
          time={good.time}
          xp={good.xp}
          source={good.source}
          image={good.image}
          needs={good.needs}
          goodsImages={goodsImages}
        />
      </Grid>
    )
  })}
</Grid>

      )
};

export default DispData;
