import { db } from '../config/firestore'
import { doc, setDoc, getDoc, collection, getDocs, addDoc } from "firebase/firestore"; 
import { goods } from '../goods';
import { Constants } from '../config/constants'

export const getGood = async (id) => {
    const noteSnapshot = await getDoc(doc(db, 'goods', id));
    if (noteSnapshot.exists()) {
        console.log(noteSnapshot.data())
        return noteSnapshot.data();
    } else {
        console.log("Note doesn't exist");
    }
};

export const getGoods = async () => {
    const goodsSnapshot = await getDocs(collection(db, Constants.goods_data_db));
    
    const goodsList = goodsSnapshot.docs.map((doc) => doc.data());
    console.log(goodsSnapshot)
    return goodsList;
};

export const getHDImages = async () => {
    const goodsSnapshot = await getDocs(collection(db, "misc-Images"));
    
    const HD_Images = goodsSnapshot.docs.map((doc) => doc.data());
    console.log(goodsSnapshot)
    return HD_Images;
}

// export const updateNote = async (note) => {
//     const noteRef = doc(db, "notes", note.id);
//     await updateDoc(noteRef, {
//         description: "New description"
//     });
// };

export const createNote = async (note) => {
    await addDoc(collection(db, 'Goods_23_sep_2024'), note);
};

export const createMessage = async (note) => {
    await addDoc(collection(db, 'contactUs'), note);
};

// for(var i=0; i<goods.length;i++){
//     createNote(goods[i]);
// }
