import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
import AdSense from '../components/googleAds';

import { useState, useEffect } from "react";
const theme = createTheme();
const BarnSizeCalculator = () => {

  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: "/barnSizeCalculator", title: "Barn Size Calculator Page Visit" });
    }, []);

  const [ini, setIni] = useState(50);
  const [fin, setFin] = useState(75);
  const [quantity, setQuantity] = useState(0);
  const [showImages, setShowImages] = useState(false);
  const [isError, setIsError] = useState(false);


  const handleSubmit = (event) => {
    setIsError(false);
    event.preventDefault();
    let x = calculate(ini, fin);
    setShowImages(true);
    setQuantity(x);
  };

  function calculate(ini, fin) {
    console.log(ini + "   "+ fin);
      ini = parseInt(ini, 10);
      fin = parseInt(fin, 10);
    let nou;
    let total = 0;
    let in_bem = 0;
    let i = 0;
    if (
      ini < 1000 &&
      fin <= 1000 &&
      ini % 25 === 0 &&
      fin % 25 === 0 &&
      ini < fin
    ) {
      nou = (fin - ini) / 25;
      in_bem = ini / 25 - 1;
      for (i = 1; i <= nou; i++) {
        total = total + in_bem;
        in_bem += 1;
      }
      console.log(total);
    } else if (
      ini < 1000 &&
      fin > 1000 &&
      ini % 25 === 0 &&
      fin % 25 === 0 &&
      ini < fin
    ) {
      nou = (1000 - ini) / 25 + (fin - 1000) / 50;
      in_bem = ini / 25 - 1;
      for (i = 1; i <= nou; i++) {
        total = total + in_bem;
        in_bem += 1;
      }
      console.log(total);
    } else if (
      ini >= 1000 &&
      fin > 1000 &&
      ini % 25 === 0 &&
      fin % 25 === 0 &&
      ini < fin
    ) {
      nou = (fin - ini) / 50;
      in_bem = 39 + (ini - 1000) / 50;
      for (i = 1; i <= nou; i++) {
        total = total + in_bem;
        in_bem += 1;
      }
      console.log(total);
    } else {
      setIsError(true);
      total = -1;
    }
    return total;
  }

  return (

      <ThemeProvider theme={theme} >
                  <Helmet>
        <title>Hay Day Price Barn Size Calculator</title>
        <meta name="description" content="Calculate the tools required to upgrade your barn or silo in Hay Day with the Barn Size Calculator. Determine the number of building materials, such as nails, planks, screws, and bolts, needed to expand your storage capacity. Plan your upgrades efficiently and optimize your resource management in Hay Day." />
        <meta name="keywords" content="Hay Day barn calculator, hay day barn size calculator, hay day barn tools, hay day silo tools calculator, hay day calculator, hay day discord" />
        <meta name="robots" content="index, follow" />
        </Helmet>
      <Container component="main" maxWidth="xs" sx={{ 
			backgroundColor: 'rgba(255, 255, 255, 0.8)',
			backdropFilter: 'blur(5px)',
			borderRadius: '10px',
			padding: '30px',
			marginTop: '50px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
      marginBottom:4
		  }}>
          <CssBaseline />
          <AdSense />
          <Box
  sx={{
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    padding: 3,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginBottom: 4,
  }}
>
  <Typography
    variant="h5"
    sx={{
      fontSize: 28,
      fontWeight: 600,
      color: '#4A4A4A',
      marginBottom: 2,
    }}
  >
    Barn Size Calculator
  </Typography>
  <Typography
    variant="body1"
    sx={{
      fontSize: 18,
      lineHeight: 1.5,
      color: '#6B6B6B',
    }}
  >
   Enter the current size of your barn and the size you want to upgrade it to and click on the calculate button below to see how many materials you'll need for the upgrade.
  </Typography>
</Box>



          <Box
            sx={{
              marginTop: 2,
              marginBottom:2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            {/* <Typography component="h1" variant="h5">
              Barn Size Calculator
            </Typography> */}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="ini"
                label="Initial Barn Size"
                name="ini"
                autoFocus
                type="number"
				InputProps={{ inputProps: { step: ini < 1000 ? 25 : 50, min: 50, max: 25000 } }}
                value={ini}
                onChange={(e) => setIni(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="fin"
                label="Final Barn Size"
                id="fin"
                type="number"
                InputProps={{ inputProps: { step: fin < 1000 ? 25 : 50, min: 50, max: 25000 } }}
                value={fin}
                onChange={(e) => setFin(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Calculate
              </Button>


              {isError && (
            <Box bgcolor="#ffcccc" p={2} borderRadius={4} mb={2}>
              <Typography variant="body1" color="textSecondary">
                There is an error in the size you entered
              </Typography>
            </Box>
          )}
        {!isError && showImages && (
           <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
            <img src="https://static.wikia.nocookie.net/hayday/images/8/8e/Bolt.png" alt="bolt" style={{ width: '100px', height: '100px' }}/>
            <span style={{ color: 'red', marginTop: '5px' }}>{quantity}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
            <img src="https://static.wikia.nocookie.net/hayday/images/4/4e/Duct_Tape.png" alt="tape" style={{ width: '100px', height: '100px' }}/>
            <span style={{ color: 'red', marginTop: '5px' }}>{quantity}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
            <img src="https://static.wikia.nocookie.net/hayday/images/0/0d/Plank.png" alt="plank" style={{ width: '100px', height: '100px' }}/>
            <span style={{ color: 'red', marginTop: '5px' }}>{quantity}</span>
          </div>
          </div>
        )} 

            </Box>
          </Box>
        </Container>
      </ThemeProvider>
	
  );
};

export default BarnSizeCalculator;


