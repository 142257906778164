import React from 'react';

const AdSense = () => {
  return (
  <div dangerouslySetInnerHTML={{
    __html: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3252607777266340"
     crossorigin="anonymous"></script>
    <ins className="adsbygoogle"
         style={{display:"block"}}
         data-ad-client="ca-pub-3252607777266340"
         data-ad-slot="2472453159"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
    <script>
      (adsbygoogle = window.adsbygoogle || []).push({});
    </script>`
  }} />
  );
};

export default AdSense;
