import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
import AdSense from '../components/googleAds';

const theme = createTheme();

const DecoCostCalculator = () => {

	useEffect(() => {
		
		ReactGA.initialize("G-LZ55FW93G8");
		ReactGA.send({ hitType: "pageview", page: "/decoCostCalculator", title: "Deco Cost Calculator Page Visit" });
	  }, []);

	const [ini, setIni] = useState(0);
	const [fin, setFin] = useState(0);
	const [quan, setQuan] = useState(0);
	const [showText, setShowText] = useState(false);
	const [amount, setAmount] = useState(0);
  
	const handleSubmit = (event) => {
	  event.preventDefault();
	  let x = calculate(ini, fin, quan);
	  setShowText(true);
	  setAmount(x);
	};
  
	function calculate(ini, fin, quan) {
	  console.log(ini + "   "+ fin);
		ini = parseInt(ini, 10);
		fin = parseInt(fin, 10);
		quan= parseInt(quan, 10);
		let change = fin - ini;
		let total = quan/2*(2*ini+(quan-1)*change);
		return total;
	}
  
	return (

		<ThemeProvider theme={theme}>
			                <Helmet>
        <title>Hay Day Decorations Cost Calculator</title>
		<meta name="description" content="Calculate the coins and diamonds required to purchase decorations in Hay Day with the Decorations Cost Calculator. Determine the cost of various decorations, including fences, trees, flowers, and more. Plan your decoration purchases effectively and manage your in-game currency to create a visually appealing and customized farm in Hay Day." />
        <meta name="keywords" content="Hay Day decorations cost calculator, hay day decorations, hay day decorations cost, hay day deco" />
        <meta name="robots" content="index, follow" />
        </Helmet>
		  <Container component="main" maxWidth="xs" sx={{ 
			backgroundColor: 'rgba(255, 255, 255, 0.8)',
			backdropFilter: 'blur(5px)',
			borderRadius: '10px',
			padding: '30px',
			marginTop: '30px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginBottom:4
		  }}>
			<CssBaseline />
			<AdSense />

			<Box
  sx={{
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    padding: 3,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginBottom: 4,
  }}
>
  <Typography
    variant="h5"
    sx={{
      fontSize: 26,
      fontWeight: 600,
      color: '#4A4A4A',
      marginBottom: 2,
    }}
  >
    Decorations Cost Calculator
  </Typography>
  <Typography
    variant="body1"
    sx={{
      fontSize: 18,
      lineHeight: 1.5,
      color: '#6B6B6B',
    }}
  >
   Enter the number of decorations you want to buy, The current price of the deco in your shop, and the new price of the deco when you buy one then click on the calculate button below to see how many coins you'll need for the upgrade.
  </Typography>
</Box>

			<Box
			  sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				
			  }}
			>

			  <Box
				component="form"
				onSubmit={handleSubmit}
				noValidate
				sx={{ mt: 1 }}
			  >
				<TextField
				  margin="normal"
				  required
				  fullWidth
				  name="quan"
				  label="Enter the number of decorations you want to buy (It should only be of one type)"
				  id="quan"
				  type="number"
				  value={quan}
				  onChange={(e) => setQuan(e.target.value)}
				/>
				<TextField
				  margin="normal"
				  required
				  fullWidth
				  id="ini"
				  label="Enter The Price Of that Deco in YOUR shop"
				  name="ini"
				  autoFocus
				  type="number"
				  value={ini}
				  onChange={(e) => setIni(e.target.value)}
				/>
				<TextField
				  margin="normal"
				  required
				  fullWidth
				  name="fin"
				  label="Enter The New Price of the deco when you buy one"
				  id="fin"
				  type="number"
				  value={fin}
				  onChange={(e) => setFin(e.target.value)}
				/>
				<Button
				  type="submit"
				  fullWidth
				  variant="contained"
				  sx={{ mt: 3, mb: 2 }}
				>
				  Calculate
				</Button>
	
				{showText && (
				<div style={{ display: 'flex' }}>
					<span style={{ color: 'red', marginTop: '5px' , fontSize: '40px'}}>Total Cost is: {amount}</span>
				</div>
				)} 
	
			  </Box>
			</Box>
		  </Container>
		</ThemeProvider>

	);
	
};

export default DecoCostCalculator;