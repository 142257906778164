/* global google */
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const GoogleTranslate = () => {
  useEffect(() => {
    const googleTranslateScript = document.createElement('script');
    googleTranslateScript.type = 'text/javascript';
    googleTranslateScript.async = true;
    googleTranslateScript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(googleTranslateScript);
  }, []);

  window.googleTranslateElementInit = function() {
    // Detect the browser's default language
    const defaultLang = navigator.language || 'en'; // Fallback to 'en' if navigator.language is undefined

    new window.google.translate.TranslateElement({
      pageLanguage: defaultLang, // Use the detected language as the default
      layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
      autoDisplay: false,
    }, 'google_translate_element');
  };

  return (
    <Box>
      <Typography variant="body1" component="span" sx={{ mr: 1 }}>
        Translate:
      </Typography>
      <div id="google_translate_element" />
    </Box>
  );
};

export default GoogleTranslate;
